// Alerts
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

// System ---------------------------------------------------
export const SHOW_ERROR = 'SHOW_ERROR';

// Content ---------------------------------------------------
export const CLICK_DATA_BUTTON = 'CLICK_DATA_BUTTON';
export const CLICK_TOOL_BUTTON = 'CLICK_TOOL_BUTTON';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SAVE_AOI = 'SAVE_AOI';
export const SAVE_AOI_SUCCESS = 'SAVE_AOI_SUCCESS';
export const SAVE_AOI_REGION = 'SAVE_AOI_REGION';
export const SAVE_AOI_UPLOAD = 'SAVE_AOI_UPLOAD';
export const CLEAR_AOI = 'CLEAR_AOI';
export const ADD_PANEL_FULL_WIDTH = 'ADD_PANEL_FULL_WIDTH';
export const REMOVE_PANEL_FULL_WIDTH = 'REMOVE_PANEL_FULL_WIDTH';
export const ADD_PANEL_FULL_HEIGHT = 'ADD_PANEL_FULL_HEIGHT';
export const REMOVE_PANEL_FULL_HEIGHT = 'REMOVE_PANEL_FULL_HEIGHT';
export const TOGGLE_SWIPE = 'TOGGLE_SWIPE';
export const GET_FEATURE_INFO = 'GET_FEATURE_INFO';
export const GET_FEATURE_INFO_STARTED = 'GET_FEATURE_INFO_STARTED';
export const GET_FEATURE_INFO_SUCCESS = 'GET_FEATURE_INFO_SUCCESS';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CHANGE_FEATURE_PANEL = 'CHANGE_FEATURE_PANEL';
export const GET_VECTOR_LAYERS = 'GET_VECTOR_LAYERS';
export const GET_VECTOR_LAYERS_SUCCESS = 'GET_VECTOR_LAYERS_SUCCESS';
export const CHANGE_QUERY_LAYER = 'CHANGE_QUERY_LAYER';
export const CHANGE_QUERY_FIELD = 'CHANGE_QUERY_FIELD';
export const CHANGE_QUERY_VALUE = 'CHANGE_QUERY_VALUE';
export const UPDATE_SWIPE_IMAGE = 'UPDATE_SWIPE_IMAGE';
export const GET_STATISTICS_STARTED = 'GET_STATISTICS_STARTED';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_GRAZING_STATISTICS_STARTED = 'GET_GRAZING_STATISTICS_STARTED';
export const GET_GRAZING_STATISTICS_SUCCESS = 'GET_GRAZING_STATISTICS_SUCCESS';
export const NO_GRAZING_AVAILABLE = 'NO_GRAZING_AVAILABLE';
export const UPDATE_STATS_LAYER = 'UPDATE_STATS_LAYER';
export const PROXY = 'PROXY';
export const CALCULATE_PRODUCT_VALUE = 'CALCULATE_PRODUCT_VALUE';
export const UPDATE_PRODUCT_VALUE = 'UPDATE_PRODUCT_VALUE';

export const TEST_GEOSERVER_API = 'TEST_GEOSERVER_API';

// Drawing
export const TOGGLE_DRAWING = 'STOP_DRAWING';
export const ADD_DRAW_POINT = 'ADD_DRAW_POINT';
export const REMOVE_DRAW_LAST_POINT = 'REMOVE_DRAW_LAST_POINT';
export const CLEAR_DRAW_COORDS = 'CLEAR_DRAW_COORDS';

// Dropzone ---------------------------------------------------
export const REMOVE_FILE_FROM_FILE_LIST = 'REMOVE_FILE_FROM_FILE_LIST';
export const FILE_ADDED_TO_LIST = 'FILE_ADDED_TO_LIST';
export const FILE_DROPED_TO_LIST = 'FILE_DROPED_TO_LIST';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED';
export const CLEAR_FILES_LIST = 'CLEAR_FILES_LIST';

// LayerItem ---------------------------------------------------
export const UPDATE_LAYER_FILL_COLOR = 'UPDATE_LAYER_FILL_COLOR';
export const UPDATE_MAP_BOUNDS = 'UPDATE_MAP_BOUNDS';
export const UPDATE_LAYER_VISIBILITY = 'UPDATE_LAYER_VISIBILITY';
export const UPDATE_LAYER_BORDER_COLOR = 'UPDATE_LAYER_BORDER_COLOR';
export const UPDATE_LAYER_BORDER_WIDTH = 'UPDATE_LAYER_BORDER_WIDTH';
export const UPDATE_MAP_LAYERS_ORDER = 'UPDATE_MAP_LAYERS_ORDER';
export const UPDATE_MAP_TABLE = 'UPDATE_MAP_TABLE';
export const REMOVE_LAYER_ITEM = 'REMOVE_LAYER_ITEM';
export const SHOW_SEARCH_RESULT_ITEM = 'SHOW_SEARCH_RESULT_ITEM';
export const CLEAR_SEARCH_RESULT_ITEM = 'CLEAR_SEARCH_RESULT_ITEM';
export const UPDATE_LAYER_VIEWPARAMS = 'UPDATE_LAYER_VIEWPARAMS';
export const ADD_LAYER_LOADING = 'ADD_LAYER_LOADING';
export const REMOVE_LAYER_LOADING = 'REMOVE_LAYER_LOADING';
export const GET_MAP_LAYERS = 'GET_MAP_LAYERS';
export const GET_MAP_LAYERS_STARTED = 'GET_MAP_LAYERS_STARTED';
export const GET_MAP_LAYERS_SUCCESS = 'GET_MAP_LAYERS_SUCCESS';
export const GET_MAP_LAYERS_FAILED = 'GET_MAP_LAYERS_FAILED';
export const SAVE_QUERY_RESULT = 'SAVE_QUERY_RESULT';
export const SAVE_QUERY_RESULT_SUCCESS = 'SAVE_QUERY_RESULT_SUCCESS';
export const UPDATE_MAP_KEY = 'UPDATE_MAP_KEY';
export const CHANGE_LAYER_OPACITY = 'CHANGE_LAYER_OPACITY';
export const GET_SENTINEL_IMAGES_SUCCESS = 'GET_SENTINEL_IMAGES_SUCCESS';
export const CLEAR_SENTINEL_IMAGES = 'CLEAR_SENTINEL_IMAGES';

// Basket ---------------------------------------------------
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const REMOVE_BASKET_ITEM = 'REMOVE_BASKET_ITEM';
export const SEND_REQUEST = 'SEND_REQUEST';
export const REQUEST_SUCCESSS = 'REQUEST_SUCCESSS';

// LoginRegister ---------------------------------------------------
export const SHOW_LOGIN_REGISTER = 'SHOW_LOGIN_REGISTER';
export const HIDE_LOGIN_REGISTER = 'HIDE_LOGIN_REGISTER';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const GET_USER_FROM_LOCAL_STORAGE = 'GET_USER_FROM_LOCAL_STORAGE';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const REGISTER_USER_STARTED = 'REGISTER_USER_STARTED';
export const REGISTER_USER_DONE = 'REGISTER_USER_DONE';

// Admin ---------------------------------------------------
// export const GET_GEOSERVER_CAPABILITIES = 'GET_GEOSERVER_CAPABILITIES';
// export const GET_GEOSERVER_CAPABILITIES_STARTED = 'GET_GEOSERVER_CAPABILITIES_STARTED';
// export const GET_GEOSERVER_CAPABILITIES_FAILED = 'GET_GEOSERVER_CAPABILITIES_FAILED';
// export const GET_MAP_LAYERS_ADMIN_SUCCESS = 'GET_MAP_LAYERS_ADMIN_SUCCESS';
// export const GET_MAP_LAYERS_ADMIN_FAILED = 'GET_MAP_LAYERS_ADMIN_FAILED';
// export const SAVE_TABLE_DATA = 'SAVE_TABLE_DATA';
export const UPDATE_MAP_LAYERS_STARTED = 'UPDATE_MAP_LAYERS_STARTED';
export const UPDATE_MAP_LAYERS_SUCCESS = 'UPDATE_MAP_LAYERS_SUCCESS';
export const UPDATE_MAP_LAYERS_FAILED = 'UPDATE_MAP_LAYERS_FAILED';

export const GET_PRODUCTS_ORDERS = 'GET_PRODUCTS_ORDERS';
export const GET_PRODUCTS_ORDERS_SUCCESS = 'GET_PRODUCTS_ORDERS_SUCCESS';
export const UPDATE_ORDER_STATE = 'UPDATE_ORDER_STATE';
export const UPDATE_ADMIN_TABLE = 'UPDATE_ADMIN_TABLE';

export const ADMIN_ACTION = 'ADMIN_ACTION';