import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    filesList: [],
    filesListProgress: []
};

const removeFileFromFileList = (state, action) => {
    let newFilesList = [...state.filesList];
    newFilesList.splice(action.index, 1);

    let newFilesListProgress = [...state.filesListProgress];
    newFilesListProgress.splice(action.index, 1);

    return updateObject(state, {filesList:newFilesList, filesListProgress:newFilesListProgress});
}

const fileAddedToList = (state, action) => {
    let newFilesList = [];
    const files = action.event.target.files;
    
    for (var i = 0; i < files.length; i++) {
        newFilesList.push(files.item(i));
    }

    return updateObject(state, {filesList:newFilesList, filesListProgress:[0]});
}

const uploadProgress = (state, action) => {
    let newfilesListProgress = [...state.filesListProgress];
    newfilesListProgress[action.fileIndex] = action.percentCompleted;

    return updateObject(state, {filesListProgress:newfilesListProgress});
}

const clearFilesList = (state) => {
    return updateObject(state, {filesList: [], filesListProgress:[]});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.REMOVE_FILE_FROM_FILE_LIST: return removeFileFromFileList(state, action);
        case actionTypes.FILE_ADDED_TO_LIST: return fileAddedToList(state, action);
        case actionTypes.FILE_DROPED_TO_LIST: return updateObject(state, {filesList:action.fileList});
        case actionTypes.UPLOAD_PROGRESS: return uploadProgress(state, action);
        case actionTypes.UPLOAD_FILES_FAILED: return updateObject(state, {error: true});
        case actionTypes.CLEAR_FILES_LIST: return clearFilesList(state);
        default: return state;
    }
};

export default reducer;