import React from 'react';

import classes from './Button.module.css';

const button = (props) => {
    
    let attachedClasses = [classes.Button];
    let style = {};

    if (props.fontColor){
        attachedClasses.push(classes[props.fontColor])
    }
    if (props.active){
        attachedClasses.push(classes.Active)
    }
    if (props.inRow){
        attachedClasses.push(classes.InRow)
    }
    if (props.style){
        style=props.style;
    }

    return(
        <button
            style={style}
            disabled={props.disabled}
            value={props.value}
            className={attachedClasses.join(' ')}
            onClick={props.clicked}
        >
            {props.children}
        </button>
    )
};

export default button;