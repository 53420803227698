import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import contentReducer from './store/reducers/content';
import dropzoneReducer from './store/reducers/dropzone';
import systemReducer from './store/reducers/system';
import layerItemsReducer from './store/reducers/layerItems';
// import productsReducer from './store/reducers/products';
import basketReducer from './store/reducers/basket';
import loginRegisterReducer from './store/reducers/loginRegister';
import alertsReducer from './store/reducers/alerts';
import adminReducer from './store/reducers/admin';

// import checkTokenMiddleware from './Middleware/checkTokenExpirationMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    system: systemReducer,
    content: contentReducer,
    dropzone: dropzoneReducer,
    layerItems: layerItemsReducer,
    // products: productsReducer,
    basket: basketReducer,
    loginRegister: loginRegisterReducer,
    alerts: alertsReducer,
    admin: adminReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
    // applyMiddleware(thunk,checkTokenMiddleware)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
serviceWorker.register();