import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import classes from './Data.module.css';
import PanelDataItem from '../../components/PanelDataItem/PanelDataItem';
import LayerItem from '../../components/LayerItem/LayerItem';
import Button from '../../components/UI/Buttons/Button';
import * as actions from '../../store/actions/index';

class Data extends Component {
    
    render () {
        let tempLayer = null;
        let sentinelLayersGroup = [];
        let demLayersGroup = [];
        let vectorLayersGroup1 = [];
        let vectorLayersGroup2 = [];
        
        this.props.mapLayers.forEach((mapLayer,index) => {
            tempLayer = <LayerItem
                            key={`data_${index}`}
                            collection="mapLayers"
                            mapLayer={mapLayer}
                            index={index}
                            showSymbol
                            showZoom
                            showVisibility
                            showOpacity
                        />

            if (mapLayer.data_group_id === 4){
                sentinelLayersGroup.push(tempLayer)
            } else if (mapLayer.data_group_id === 2){
                demLayersGroup.push(tempLayer)
            } else if (mapLayer.data_group_id === 3 && mapLayer.data_subgroup_id === 1){
                vectorLayersGroup1.push(tempLayer)
            } else if (mapLayer.data_group_id === 3 && mapLayer.data_subgroup_id === 2){
                vectorLayersGroup2.push(tempLayer)
            }
        });

        let sentinelImages = <div className={classes.SelectAOIMessage}>
                                <span>Select Area of interest</span>
                                <Button clicked={() => this.props.onClickDataButton(0)}>
                                    <span>Go to AOI</span>
                                </Button>  
                            </div>
        if (this.props.aoi) {
            if (sentinelLayersGroup.length > 0){
                sentinelImages = <div>
                                    {sentinelLayersGroup}
                                </div>
            } else {
                sentinelImages = <div>No sentinel images were found in the selected area.</div>
            } 
        }

        return(
            <Tabs
                className={classes.Tabs}
                selectedTabClassName={classes.SelectedTab}
            >
                <TabList className={classes.TabList}>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M502.7 265l-80.3-80.4 47.8-47.9c13.1-13.1 13.1-34.4 0-47.5l-47.5-47.5c-13.1-13.1-34.4-13.1-47.5 0l-47.8 47.9-80.3-80.3C240.8 3.1 232.7 0 224.5 0S208.2 3.1 202 9.3L105.3 106c-12.4 12.4-12.4 32.6 0 45.1l80.3 80.4-9.8 9.8C122.1 217 59.6 218.6 7.3 246.7c-8.5 4.6-9.6 16.4-2.8 23.2L112 377.4l-17.8 17.8c-2.6-.7-5-1.6-7.8-1.6-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32c0-2.8-.9-5.2-1.6-7.8l17.8-17.8 107.5 107.5c6.8 6.8 18.7 5.7 23.2-2.8 28.1-52.3 29.7-114.8 5.4-168.5l9.9-9.9 80.3 80.4c6.2 6.2 14.4 9.3 22.5 9.3s16.3-3.1 22.5-9.3l96.7-96.7c12.5-12.4 12.5-32.6.1-45zm-352-136.5l73.8-73.8 68.9 68.9-73.8 73.8-68.9-68.9zm232.8 232.8l-68.9-68.9 73.8-73.8 68.9 68.9-73.8 73.8z"></path>
                            </svg>
                        </div>
                        <span>Satellite</span>
                    </Tab>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                <path d="M634.92 462.7l-288-448C341.03 5.54 330.89 0 320 0s-21.03 5.54-26.92 14.7l-288 448a32.001 32.001 0 0 0-1.17 32.64A32.004 32.004 0 0 0 32 512h576c11.71 0 22.48-6.39 28.09-16.67a31.983 31.983 0 0 0-1.17-32.63zM320 91.18L405.39 224H320l-64 64-38.06-38.06L320 91.18z"></path>
                            </svg>
                        </div>
                        <span>DEM</span>
                    </Tab>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.712 39.406">
                                <path d="M.195 13.635l24.67 13.017 24.423-13.017L24.866-.002z"/>
                                <path d="M7.935 21.43L.001 25.769l24.794 13.637 24.918-13.637-7.558-4.339-17.356 9.422z"/>
                            </svg>
                        </div>
                        <span>Layers</span>
                    </Tab>
                </TabList>

                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="Satellite data"
                            info="View available satellite and Copernicus land monitoring data">

                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Sentinel-2 L 1C and L 2A products</h4>

                                {sentinelImages}
                            </div>

                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Copernicus Land Monitoring Service</h4>
                                <div>
                                    <a href="https://www.copernicus.eu/en" target="_blank" rel="noopener noreferrer">www.copernicus.eu</a>
                                </div>
                            </div>
                        </PanelDataItem>
                    </div>
                </TabPanel>
                
                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="SRTM DEM (30m)"
                            info="View available SRTM DEM data">
                        
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>SRTM DEM (30m)</h4>

                                {demLayersGroup.map((demLayer, idx) => 
                                    demLayer
                                )}
                            </div>
                            
                        </PanelDataItem>
                    </div>
                </TabPanel>
                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="Vector data"
                            info="View available vector data grouped in different layers">
                                
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Administrative</h4>
                                <div>
                                    {vectorLayersGroup1}
                                </div>
                            </div>

                            <div className={classes.SubCategory}>
                                {
                                    vectorLayersGroup2.length > 0
                                    ? <h4 className={classes.SubTitle}>Environment</h4>
                                    : null
                                }
                                <div>
                                    {vectorLayersGroup2}
                                </div>
                            </div>
                        </PanelDataItem>
                    </div>
                </TabPanel>
            </Tabs>
        )
    }
}

const mapStateToProps = state => {
    return {
        mapLayers: state.layerItems.mapLayers,
        aoi: state.content.aoi
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onClickDataButton: (index) => dispatch(actions.clickDataButton(index)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data);
