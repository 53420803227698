import * as actionTypes from './actionTypes';
import axios from '../../axios-api';
import {alertSuccess, alertError} from './alerts';
import {getMapLayers} from './layerItems';
import {getVectorLayers2} from './content';

export const showLoginRegister = (showType) => {
    return {
        type: actionTypes.SHOW_LOGIN_REGISTER,
        showType: showType
    };
};

export const hideLoginRegister = () => {
    return {
        type: actionTypes.HIDE_LOGIN_REGISTER
    };
};

export const checkLogin = (loginInfo) => {
    return dispatch => {
        axios.post( 'checkLogin.php', loginInfo )
            .then(response => {
                const data = response.data.text && JSON.parse(response.data.text);
                localStorage.setItem('user', JSON.stringify(data));
                dispatch(loggedIn(data));
                dispatch(getMapLayers());
                dispatch(getVectorLayers2());
            })
            .catch( error => {
                dispatch(alertError(error.response.data.message));
            });
    };
};

export const loggedIn = (user) => {
    return {
        type: actionTypes.LOGGED_IN,
        user:user
    };
};

export const logOut = () => {
    return dispatch => {
        dispatch(loggedOut());
        dispatch(getMapLayers());
        dispatch(getVectorLayers2());
    };
};

export const loggedOut = () => {
    localStorage.removeItem('user');

    return {
        type: actionTypes.LOGGED_OUT
    };
};

export const registerUser = (userData) => {
    return dispatch => {
        dispatch(registerUserStarted());

        axios.post( 'register.php', userData )
            .then( response => {
                dispatch(registerUserDone());
                dispatch(alertSuccess(response.data));
            })
            .catch( error => {
                dispatch(registerUserDone());
                dispatch(alertError(error.response.data.message));
            });
    };
};

export const registerUserStarted = () => {
    return {
        type: actionTypes.REGISTER_USER_STARTED
    };
};

export const registerUserDone = () => {
    return {
        type: actionTypes.REGISTER_USER_DONE
    };
};

export const getUserFromLocalStorage = () => {
    return {
        type: actionTypes.GET_USER_FROM_LOCAL_STORAGE
    };
};

export const accountActivation = (activationKey) => {
    return dispatch => {
        axios.post( 'accountActivation.php', {activationKey:activationKey})
            .then( response => {
                dispatch(showConfirmation(response.data));
            })
            .catch( error => {
                dispatch(showConfirmation(error.response.data.message));
            });
    };
};

export const showConfirmation = (confirmationMessage) => {
    return {
        type: actionTypes.SHOW_CONFIRMATION,
        confirmationMessage: confirmationMessage
    };
};

export const sendResetPasswordEmail = (email, password) => {
    return dispatch => {
        axios.post( 'sendResetPasswordEmail.php', {email:email,password:password})
            .then( response => {
                dispatch(alertSuccess(response.data));
            })
            .catch( error => {
                dispatch(alertError(error.response.data.message));
            });
    };
};

export const updatePassword = (activationKey) => {
    return dispatch => {

        axios.post( 'updatePassword.php', {activationKey:activationKey})
            .then( response => {
                dispatch(showConfirmation(response.data));
            })
            .catch( error => {
                dispatch(showConfirmation(error.response.data.message));
            });
    };
};