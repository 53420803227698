import * as actionTypes from './actionTypes';
import axiosRoot from 'axios';
import axios from '../../axios-api';

export const clickDataButton = (index) => {
    return {
        type: actionTypes.CLICK_DATA_BUTTON,
        index: index
    };
};

export const clickToolButton = (index) => {
    return {
        type: actionTypes.CLICK_TOOL_BUTTON,
        index: index
    };
};

export const toggleSidebar = () => {
    return {
        type: actionTypes.TOGGLE_SIDEBAR
    };
};

export const toggleDrawing = (value) => {
    return {
        type: actionTypes.TOGGLE_DRAWING,
        value: value
    };
};

export const addDrawPoint = (latlng) => {
    return {
        type: actionTypes.ADD_DRAW_POINT,
        latlng: latlng
    };
};

export const removeDrawLastPoint = () => {
    return {
        type: actionTypes.REMOVE_DRAW_LAST_POINT
    };
};

export const clearDrawCoords = () => {
    return {
        type: actionTypes.CLEAR_DRAW_COORDS
    };
};

export const addPanelFullWidth = () => {
    return {
        type: actionTypes.ADD_PANEL_FULL_WIDTH
    };
};

export const removePanelFullWidth = () => {
    return {
        type: actionTypes.REMOVE_PANEL_FULL_WIDTH
    };
};

export const addPanelFullHeight = () => {
    return {
        type: actionTypes.ADD_PANEL_FULL_HEIGHT
    };
};

export const removePanelFullHeight = () => {
    return {
        type: actionTypes.REMOVE_PANEL_FULL_HEIGHT
    };
};

export const toggleSwipe = () => {
    return {
        type: actionTypes.TOGGLE_SWIPE
    };
};

export const getFeatureInfo = (url, layers) => {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem('user'));
        let token = null;

        if (user){
            token = user.token;
        }

        dispatch(getFeatureInfoStarted());
        axiosRoot.all(layers.map(layer => axios.get(`proxy.php?&token=${token}&request=GetFeatureInfo&layers=${layer.layerName}&query_layers=${layer.layerName}${url}`)))
            .then(axiosRoot.spread((...res) => {
                // all requests are now complete
                dispatch(getFeatureInfoSuccess(layers, res));
        }));   
    };
};

export const getFeatureInfoStarted = () => {
    return {
        type: actionTypes.GET_FEATURE_INFO_STARTED
    };
};

export const getFeatureInfoSuccess = (layers, layersData) => {
    return {
        type: actionTypes.GET_FEATURE_INFO_SUCCESS,
        layers: layers,
        layersData: layersData
    };
};

export const closePopup = () => {
    return {
        type: actionTypes.CLOSE_POPUP
    };
};

export const changeFeaturePanel = (action) => {
    return {
        type: actionTypes.CHANGE_FEATURE_PANEL,
        action:action
    };
};

export const clearAOI = () => {
    return {
        type: actionTypes.CLEAR_AOI
    };
};

export const saveAOI = (aoiPolygon) => {
    return dispatch => {
        axios.post( 'addGeojson.php', {aoiPolygon:aoiPolygon})
            .then( response => {
                dispatch(saveAOISuccess(aoiPolygon, response.data));
                dispatch(getSentinelImages(parseInt(response.data.id)));
            })
            .catch( error => {
                // dispatch(saveAOIFailed());
            });
    };
};

export const saveAOISuccess = (aoiPolygon, uploadedItemId) => {
    return {
        type: actionTypes.SAVE_AOI_SUCCESS,
        aoiPolygon: aoiPolygon,
        uploadedItemId: uploadedItemId
    };
};

export const saveAOIRegion = (params) => {
    return dispatch => {
        axios.post( 'updateUserAoi.php', params)
            .then( response => {
                dispatch(saveAOIRegionSuccess(params, response.data));
                dispatch(getSentinelImages(response.data));
            })
            .catch( error => {
                
            });
    };
};

export const saveAOIRegionSuccess = (params, uploadedItemId) => {
    return dispatch => {
        const url = `proxy.php?&request=GetFeature&typeNameAddWorkspace=${params.region}&CQL_FILTER=(id=${params.id})&outputFormat=application/json`

        axios.get(url)
            .then( response => {
                dispatch(saveAOISuccess(response.data, {id:uploadedItemId}));
            })
            .catch( error => {

            });    
    };
};

export const getVectorLayers2 = () => {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem('user'));
        let token = null;

        if (user){
            token = user.token;
        }

        axios.post( 'getVectorLayers.php', {"jwt":token})
            .then( response => {
                dispatch(getVectorLayersSuccess(response.data));
            })
            .catch( error => {
                // dispatch(getVectorLayersFailed());
            });
    };
};

export const getVectorLayersSuccess = (vectorLayers) => {
    return {
        type: actionTypes.GET_VECTOR_LAYERS_SUCCESS,
        vectorLayers: vectorLayers
    };
};

export const changeQueryLayer = (layer) => {
    return {
        type: actionTypes.CHANGE_QUERY_LAYER,
        layer: layer
    };
};

export const changeQueryField = (field) => {
    return {
        type: actionTypes.CHANGE_QUERY_FIELD,
        field: field
    };
};

export const changeQueryValue = (value) => {
    return {
        type: actionTypes.CHANGE_QUERY_VALUE,
        value: value
    };
};

export const updateSwipeImage = (mapLayerIndex, position) => {
    return {
        type: actionTypes.UPDATE_SWIPE_IMAGE,
        mapLayerIndex: mapLayerIndex,
        position: position
    };
};

export const getStatistics = (vectorID, rasterFilename) => {
    return (dispatch, getState) => {
        const phpUrl = getState().system.phpUrl;

        let geoserverLayer = "stats_no_intersection";
        let url = `${phpUrl}proxy.php?&request=GetStats&geoserverLayer=${geoserverLayer}&viewparams=RASTER_FILENAME:${rasterFilename}`;
    
        if (vectorID){
            geoserverLayer = "stats_intersection";
            url = `${phpUrl}proxy.php?&request=GetStats&geoserverLayer=${geoserverLayer}&viewparams=RASTER_FILENAME:${rasterFilename};VECTOR_ID:${vectorID}`;
        }

        dispatch(getStatisticsStarted());
        axiosRoot.get(url)
            .then( response => {
                dispatch(getStatisticsSuccess(response.data));
            })
            .catch( error => {

            });    
    };
};

export const getStatisticsStarted = () => {
    return {
        type: actionTypes.GET_STATISTICS_STARTED
    };
};

export const getStatisticsSuccess = (statistics) => {
    return {
        type: actionTypes.GET_STATISTICS_SUCCESS,
        statistics: statistics
    };
};

export const updateStatsLayer = (statsLayerName) => {
    return {
        type: actionTypes.UPDATE_STATS_LAYER,
        statsLayerName: statsLayerName
    };
};

export const getSentinelImages = (aoi_id) => {
    return dispatch => {
        axios.post( 'getSentinelImages.php',{aoi_id:aoi_id})
            .then( response => {
                dispatch(getSentinelImagesSuccess(response.data));
            })
            .catch( error => {
                // dispatch(getSentinelImagesFailed());
            });
    };
};

export const getSentinelImagesSuccess = (sentinelImages) => {
    return {
        type: actionTypes.GET_SENTINEL_IMAGES_SUCCESS,
        sentinelImages: sentinelImages
    };
};

export const getGrazingStatistics = (vectorID, rasterFilename, animal) => {
    return (dispatch, getState) => {
        const phpUrl = getState().system.phpUrl;

        let geoserverLayer = "stats_no_intersection";
        let url = `${phpUrl}proxy.php?&request=GetStats&geoserverLayer=${geoserverLayer}&viewparams=RASTER_FILENAME:${rasterFilename}`;
    
        if (vectorID){
            geoserverLayer = "stats_intersection";
            url = `${phpUrl}proxy.php?&request=GetStats&geoserverLayer=${geoserverLayer}&viewparams=RASTER_FILENAME:${rasterFilename};VECTOR_ID:${vectorID}`;
        }


        dispatch(getGrazingStatisticsStarted());
        axiosRoot.get(url)
            .then( response => {
                dispatch(getGrazingStatisticsSuccess(response.data,animal));
            })
            .catch( error => {

            });    
    };
};

export const getGrazingStatisticsStarted = () => {
    return {
        type: actionTypes.GET_GRAZING_STATISTICS_STARTED
    };
};

export const getGrazingStatisticsSuccess = (statistics,animal) => {
    return {
        type: actionTypes.GET_GRAZING_STATISTICS_SUCCESS,
        statistics: statistics,
        animal: animal
    };
};

export const noGrazingAvailable = () => {
    return {
        type: actionTypes.NO_GRAZING_AVAILABLE
    };
};

export const proxy = () => {
    return dispatch => {
        axios.get( 'proxy.php')
            .then( response => {
                console.log(JSON.parse(response.data))
            })
            .catch( error => {

            });
    };
};

export const calculateProductValue = (aoi_id) => {
    return dispatch => {
        axios.post( 'calculateProductValue.php', {aoi_id:aoi_id})
            .then( response => {
                dispatch(updateProductValue(response.data));
            })
            .catch( error => {

            });
    };
};

export const updateProductValue = (productValue) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_VALUE,
        productValue: productValue
    };
};