import React from 'react';

import classes from './Spinner.module.css';

const spinner = (props) => {
    let spinnerClasses = [classes.Spinner]

    if (props.themeLight){
        spinnerClasses.push(classes.ThemeLight)
    }
    if (props.themeDark){
        spinnerClasses.push(classes.ThemeDark)
    }

    return(
        <div className={spinnerClasses.join(' ')}></div>
    )
};

export default spinner;