import React from 'react';

import classes from './MenuButton.module.css';

const menuButton = (props) => {
    
    let attachedClasses = [classes.MenuButton];

    if (props.active){
        attachedClasses.push(classes.Active)
    }

    return(
        <button
            disabled={props.disabled}
            value={props.value}
            className={attachedClasses.join(' ')}
            onClick={props.clicked}
        >
            {props.children}
        </button>
    )
};

export default menuButton;