import * as actionTypes from './actionTypes';

export const alertSuccess = (message) => {
    return {
        type: actionTypes.ALERT_SUCCESS,
        message: message
    };
};

export const alertError = (message) => {
    return {
        type: actionTypes.ALERT_ERROR,
        message: message
    };
};

export const alertClear = () => {
    return {
        type: actionTypes.ALERT_CLEAR
    };
};