import React from 'react';

import classes from './CloseButton.module.css';

const closeButton = (props) => {

    let buttonClasses = [classes.CloseButton]
    if (props.red){
        buttonClasses.push(classes.Red)
    }

    return(
        <div
            className={buttonClasses.join(' ')}
            onClick={props.clicked}
        >
        </div>
    )
};

export default closeButton;