import React, { Component } from 'react';
import { Route, Switch, Redirect  } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import Content from './containers/Content/Content';
import Admin from './containers/Admin/Admin';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { Role } from './_helpers/Role';

class App extends Component {
  render () {
    return (
        <Layout>
          <Switch>
              <PrivateRoute path="/admin" roles={[Role.Admin]} component={Admin} />
              <Route path="/" exact component={Content} />
              <Redirect from="*" to="/" />
          </Switch>
        </Layout>
    );
  }
}

export default App;
