import React from 'react';

import classes from './DropzoneButton.module.css';

const dropzoneButton = (props) => (
    <button
        disabled={props.disabled}
        value={props.value}
        className={[classes.Button, classes[props.btnType], classes[props.btnShape], classes[props.btnSize], classes[props.fontColor], classes[props.fontWeight], classes[props.extraClass]].join(' ')}
        onClick={props.clicked}>{props.children}
    </button>      
);

export default dropzoneButton;