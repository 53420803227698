import React, { Component } from 'react';

import classes from './PanelDataItem.module.css';
import InfoButton from '../UI/Buttons/InfoButton';

class PanelDataItem extends Component {

    state={
        showInfo:false
    }

    toggleInfoVisibilityHandler = () => {
        this.setState( ( prevState ) => {
            return { showInfo: !prevState.showInfo };
        } );
    }

    render () {

        let attachedClasses = [classes.PanelDataItem];

        if (this.state.showInfo === true) {
            attachedClasses.push(classes.ShowInfo);
        }

        if (this.props.noTitle === true) {
            attachedClasses.push(classes.NoTitle);
        }

        return(
            <div className={attachedClasses.join(' ')}>
                <div className={classes.TitleWrapper}>
                    <h3>{this.props.title}</h3>
                    {this.props.info ?
                        <div>
                            <InfoButton 
                                clicked={this.toggleInfoVisibilityHandler}
                            >
                                i
                            </InfoButton>
                            <div className={classes.Info}>
                                <div className={classes.InfoInner}>{this.props.info}</div>
                            </div>
                        </div>
                    : null
                    }
                </div>

                {this.props.children}
            </div>
        )
    }
};

export default PanelDataItem;