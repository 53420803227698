export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};

export const checkInputValidity = (value, rules) => {
    let isValid = true;

    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^-?\d+\.?\d*$/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isEGSAx) {
        isValid = value >= 95000 && value <= 1050000 && isValid
    }

    if (rules.isEGSAy) {
        isValid = value >= 3700000 && value <= 4700000 && isValid
    }

    if (rules.isWGSx) {
        isValid = value >= -180 && value <= 180 && isValid
    }

    if (rules.isWGSy) {
        isValid = value >= -90 && value <= 90 && isValid
    }

    return isValid;
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return hexToRgb(color);
}

// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
// Adam Cole, 2011-Sept-14
// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
export const getRainbowColor = (numOfSteps, step) => {
    let r, g, b;
    const h = step / numOfSteps;
    const i = ~~(h * 6);
    const f = h * 6 - i;
    const q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
        default: r = 1; g = f; b = 0;
    }
    const color = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return color;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

// function rgbToHex(r, g, b) {
//     return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
// }

export function arrayMove(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }
    while (newIndex < 0) {
        newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    //return arr;
};

export const getBoundsFromCoords = (coordsArr) => {

    let largestX = -100000000;
    let largestY = -100000000;
    let lowestX = 100000000;
    let lowestY = 100000000;

    
    for(let i = 0; i < coordsArr.length; i++) {
        if (coordsArr[i].lng > largestX) {
            largestX = coordsArr[i].lng
        }
        if (coordsArr[i].lat > largestY) {
            largestY = coordsArr[i].lat
        }
        if (coordsArr[i].lng < lowestX) {
            lowestX = coordsArr[i].lng
        }
        if (coordsArr[i].lat < lowestY) {
            lowestY = coordsArr[i].lat
        }
    }

    // When point, zoom out a little
    if (lowestY === largestY && lowestX === largestX){
        lowestY = lowestY - 0.4;
        largestY = largestY + 0.4;
        lowestX = lowestX - 0.4;
        largestX = largestX + 0.4;
    }

    let newBounds = [[lowestY,largestX],[largestY,lowestX]];
    
    return newBounds;
}