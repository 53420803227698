import React from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginBottom: theme.spacing(1),
  },
}));

const ComboboxAsync = (props) => {
  const defaultProps = {
    options: props.options,
    getOptionLabel: (option) => {
      if (option.label === null) return ""
      return option.label
    }
  };

  const classes = useStyles();

  const theme = createTheme({
      palette: {
          primary: {
              main: '#82AE2C'
          },
      },
  });

  return (
    <div>
      <FormControl className={classes.formControl} disabled={props.disabled}>
        <ThemeProvider theme={theme}>
            <Autocomplete
              {...defaultProps}
              className={classes.selectEmpty}
              renderInput={(params) => <TextField variant="standard" {...params} label={props.label} />}
              onChange={props.changed}
              disableClearable
              disabled={props.disabled}
            />
        </ThemeProvider>
      </FormControl>
    </div>
  )
}

export default ComboboxAsync;