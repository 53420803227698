import React from 'react';
import Slider from '@material-ui/core/Slider';

import classes from './OpacitySlider.module.css';


const opacitySlider = (props) => {

  const marks = [
    {value: 0, label: '0%'},
    {value: 100, label: '100%'}
  ];

  return (
      <div className={classes.Root}>
        <Slider
          valueLabelDisplay="auto"
          track={false}
          min={0}
          max={100}
          marks={marks}
          value={props.percentage}
          onChange={(e, value) => props.changed(value)}
          classes={{
            track: classes.Track,
            markActive: classes.MarkActive,
            markLabel: classes.MarkLabel,
            thumb: classes.Thumb,
            valueLabel: classes.ValueLabel,
            rail: classes.Rail
          }}
        />
      </div>
  );
}

export default opacitySlider;