import React from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginBottom: theme.spacing(1),
  },
}));

const SimpleSelect = (props) => {
    
    const classes = useStyles();
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = event => {
        setValue(event.target.value);
        props.changed(event.target.value);
    };
  
    const theme = createTheme({
        palette: {
            primary: {
                main: '#82AE2C'
            },
        },
    });

    return (
        <div>
            <FormControl className={classes.formControl} disabled={props.disabled}>
                <ThemeProvider theme={theme}>
                    <Select 
                        value={props.options.filter(x => x.value === value).length > 0 ? value : ""}
                        onChange={handleChange}
                        displayEmpty 
                        className={classes.selectEmpty}>

                        <MenuItem value="" disabled>
                            {props.placeholder}
                        </MenuItem>

                        {props.options.map((option,index) => (
                            <MenuItem key={"menuItem_" + index} value={option.value}>{option.label}</MenuItem>
                        ))}

                    </Select>
                </ThemeProvider>
            </FormControl>      
        </div>
    );
}

export default SimpleSelect