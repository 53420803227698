import React from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from '../../../axios-api';


export default function Asynchronous(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [doneLoading, setDoneLoading] = React.useState(false);

  const loading = open && options.length === 0 && doneLoading === false;

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    selectEmpty: {
      marginBottom: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const theme = createTheme({
      palette: {
          primary: {
              main: '#82AE2C'
          },
      },
  });

  
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    
    (async () => {
      axios.post("getRegion.php", {selRegionType:props.selRegionType, selCountryId:props.selCountryId, selProvinceId:props.selProvinceId})
        .then(response => {
          if (active) {
            setDoneLoading(true)
            setOptions(response.data)
          }
        })
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
        <FormControl className={classes.formControl}>
            <ThemeProvider theme={theme}>
              <Autocomplete
                  disabled={props.disabled}
                  open={open}
                  onOpen={() => {
                    setDoneLoading(false);
                    setOpen(true);
                  }}
                  onClose={(event) => {
                    if (event.target.value !== 0) {
                      const sel = {
                        gid:props.selectedRegions[props.selRegionType + "Gid"],
                        label:props.selectedRegions[props.selRegionType],
                        value:props.selectedRegions[props.selRegionType + "ID"]
                      }
                      props.changed(event, sel)
                    }
                    setOpen(false);
                  }}
                  inputValue={props.selectedValue}
                  onChange={props.changed}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) => option.label === value.label }
                  options={options}
                  loading={loading}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.label}
                      autoComplete='chrome-off'
                      onChange={(event) => {
                        props.typed(event.target.value)
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
            </ThemeProvider>
        </FormControl>
    </div>
  );
}