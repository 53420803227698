import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Downloads.module.css';
import PanelDataItem from '../../components/PanelDataItem/PanelDataItem';
import DownloadItem from '../../components/DownloadItem/DownloadItem';
import * as actions from '../../store/actions/index';

class Downloads extends Component {

    componentDidMount() {
        this.props.onAddPanelFullHeight()
        this.props.onAddPanelFullWidth();
    }

    componentWillUnmount() {
        this.props.onRemovePanelFullWidth();
        this.props.onRemovePanelFullHeight();
    }

    render(){
        return (
            <div className={classes.DownloadsWrapper}>
                <PanelDataItem
                    title="Downloads"
                    info="Download data according to user’s access rights">

                    <div className={classes.DownloadItems}>
                        {
                            this.props.mapLayers.map((mapLayer,index) => {
                                if (mapLayer.downloads === true){
                                    return <DownloadItem 
                                                key={index}
                                                mapLayer={mapLayer}
                                            />
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </PanelDataItem>
            </div>
        )
    }
};


const mapStateToProps = state => {
    return {
        mapLayers: state.layerItems.mapLayers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAddPanelFullWidth: () => dispatch(actions.addPanelFullWidth()),
        onRemovePanelFullWidth: () => dispatch(actions.removePanelFullWidth()),
        onAddPanelFullHeight: () => dispatch(actions.addPanelFullHeight()),
        onRemovePanelFullHeight: () => dispatch(actions.removePanelFullHeight())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);