import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    basketItems: [],
    basketItemsNum: 0,
    requestID:null
}

const addToBasket = (state, action) => {
    let basketItems = [...state.basketItems];
    let basketItemStr = null;
    const newItem = action.basketItem;
    const newItemStr = JSON.stringify(newItem);

    let itemExists = false;

    basketItems.forEach(basketItem => {
        basketItemStr = JSON.stringify(basketItem);
        if (newItemStr === basketItemStr) itemExists = true;
    });

    if (!itemExists) basketItems.push(action.basketItem)
    
    return updateObject(state, {
        basketItems:basketItems, 
        basketItemsNum: basketItems.length,
        requestID:null
    });
}

const removeBasketItem = (state, action) => {
    let basketItems = [...state.basketItems];
    basketItems.splice(action.index, 1);
    
    return updateObject(state, {
        basketItems:basketItems, 
        basketItemsNum: basketItems.length
    });
}

const requestSuccess = (state, action) => {
    return updateObject(state, {
        requestID:action.requestID,
        basketItems:[],
        basketItemsNum:0
    });
}



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADD_TO_BASKET: return addToBasket(state, action);
        case actionTypes.REMOVE_BASKET_ITEM: return removeBasketItem(state, action);
        case actionTypes.REQUEST_SUCCESSS: return requestSuccess(state, action);
        default: return state;
    }
};

export default reducer;