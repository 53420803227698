import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    productsOrders:{states:[],orders:[]}
};


const updateAdminTable = (state, action) => {
    let orders = [...state.productsOrders.orders];
    let rowData = {...action.value};

    if (action.actionType === "update"){
        delete rowData.tableData;
        orders[action.index] = rowData;

    } else if (action.actionType === "delete"){
        orders.splice(action.index, 1);
    }

    const productsOrders = {states:[...state.productsOrders.states],orders:orders}

    return updateObject(state, {productsOrders:productsOrders});
}



const getProductsOrdersSuccess = (state, action) => {
    return updateObject(state, {productsOrders:action.orders});
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_ADMIN_TABLE: return updateAdminTable(state, action);
        case actionTypes.GET_PRODUCTS_ORDERS_SUCCESS: return getProductsOrdersSuccess(state, action);
        default: return state;
    }
};

export default reducer;