import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';

import Swal from 'sweetalert2';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import classes from './Admin.module.css';
import Button from '../../components/UI/Buttons/Button';
import PanelDataItem from '../../components/PanelDataItem/PanelDataItem';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import * as actions from '../../store/actions/index';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class Admin extends Component {
 
    state={
        changedRows: []
    }
    
    componentDidMount () {
        this.props.onGetProductsOrders();
    }

    render () {
        let columns = [];
        let states = {};

        this.props.productsOrders.states.forEach((state,index) => {
            states[state] = state;
        });

        columns = [{
            title: 'Request id',
            field: 'request_id',
            editable: 'never'
        },{
            title: 'Order date',
            field: 'date',
            editable: 'never'
        },{
            title: 'Username',
            field: 'username',
            editable: 'never'
        },{
            title: 'User name',
            field: 'name',
            editable: 'never'
        },{
            title: 'User email',
            field: 'email',
            editable: 'never'
        },{
            title: 'Item date',
            field: 'item_date',
            editable: 'never'
        },{
            title: 'aoi id',
            field: 'user_aoi_id',
            editable: 'never'
        },{
            title: 'Area ha',
            field: 'area_ha',
            editable: 'never'
        },{
            title: 'Value',
            field: 'item_value',
            editable: 'never'
        },{
            title: 'State',
            field: 'state',
            lookup: states
        }];
        
        return(
            <Aux>
                <div className={classes.AdminWrapper}>
                    <PanelDataItem title="Admin">
                    
                        <div className={classes.AvailableTitle}>
                            <span>Products orders</span>
                        </div>

                        {this.props.productsOrders.orders.length > 0 ?
                            <MaterialTable
                                icons={tableIcons}
                                columns={columns}
                                data={this.props.productsOrders.orders}
                                title=""
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        newData.state === "Paid" && oldData.state !== "Paid"
                                        ? 
                                            Swal.fire({
                                                title: 'Are you sure',
                                                text: 'An email will be sent to the user that the payment was received',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                showLoaderOnConfirm: true,
                                                allowOutsideClick: false,
                                                preConfirm: () => {
                                                    const data = this.props.productsOrders.orders;
                                                    const index = data.indexOf(oldData);
                                                    this.props.onUpdateOrderState(index,newData,"update");
                                                }
                                            }).then((result) => {
                                                if (result.value) {
                                                //     new Promise((resolve, reject) => {
                                                //         {
                                                //             const data = this.props.productsOrders.orders;
                                                //             const index = data.indexOf(oldData);
                                                //             this.props.onUpdateOrderState(index,newData,"update");
                                                //         }
                                                //         resolve()
                                                //     })

                                                    Swal.fire(
                                                        'Success!',
                                                        'An email was sent to the user.',
                                                        'success'
                                                    )
                                                }
                                            })

                                        : newData.state === "Produced" && oldData.state !== "Produced"
                                            ?
                                                Swal.fire({
                                                    title: 'Are you sure the product is produced',
                                                    text: 'The product will be made available',
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    showLoaderOnConfirm: true,
                                                    allowOutsideClick: false,
                                                    preConfirm: () => {
                                                        const data = this.props.productsOrders.orders;
                                                        const index = data.indexOf(oldData);
                                                        this.props.onUpdateOrderState(index,newData,"update");
                                                    }
                                                }).then((result) => {
                                                    if (result.value) {
                                                        // new Promise((resolve, reject) => {
                                                        //     {
                                                        //         const data = this.props.productsOrders.orders;
                                                        //         const index = data.indexOf(oldData);
                                                        //         this.props.onUpdateOrderState(index,newData,"update");
                                                        //     }
                                                        //     resolve();
                                                        // })

                                                        Swal.fire(
                                                            'Success!',
                                                            'The product was produced.',
                                                            'success'
                                                        )
                                                    }
                                                })

                                            : 
                                                new Promise((resolve, reject) => {
                                                    resolve()
                                                }) 
                                }}
                            />

                            : null
                        }
                        
                        {/* <div className={classes.SaveWrapper}>
                            <Button clicked={() => this.props.onUpdateMapLayers(this.props.tableLayers)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"></path></svg>
                                <span>Save</span>
                            </Button>
                        </div> */}
                    </PanelDataItem>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        productsOrders: state.admin.productsOrders
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetProductsOrders: () => dispatch(actions.getProductsOrders()),
        onUpdateOrderState: (index, value, actionType) => dispatch(actions.updateOrderState(index, value, actionType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);