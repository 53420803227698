import React, { Component } from 'react';

import classes from './Panel.module.css';
import CloseButton from '../Buttons/CloseButton';

class Panel extends Component {
    state = {
        panelClosed: false,
        panelFullScreen: false
    }
    
    togglePanelVisibilityHandler = () => {
        this.setState( ( prevState ) => {
            return { panelClosed: !prevState.panelClosed };
        } );
    }

    toggleFullScreenHandler = () => {
        this.setState( ( prevState ) => {
            return { panelFullScreen: !prevState.panelFullScreen };
        });
    }

    render () {
        let attachedClasses = [classes.Panel];

        if (this.state.panelClosed === true) {
            attachedClasses.push(classes.PanelClosed);
        }
        if (this.state.panelFullScreen === true) {
            attachedClasses.push(classes.PanelFullScreen);
        }
        if (this.props.show) {
            attachedClasses.push(classes.Show);
        } 
        if (this.props.drawForm) {
            attachedClasses.push(classes.DrawForm);
        }
        if (this.props.fullWidthForm) {
            attachedClasses.push(classes.FullWidthForm);
        }
        if (this.props.fullHeightForm) {
            attachedClasses.push(classes.FullHeightForm);
        }
        

        return(
            <div className={attachedClasses.join(' ')}>
                <div className={classes.CloseButtonWrapper}>
                    <CloseButton clicked={this.props.onClose} />
                </div>
                
                <div className={classes.PanelContent}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Panel;