import * as actionTypes from './actionTypes';
import axios from '../../axios-api';

export const addToBasket = (basketItem) => {
    return {
        type:actionTypes.ADD_TO_BASKET,
        basketItem:basketItem
    };
};

export const removeBasketItem = (index) => {
    return {
        type:actionTypes.REMOVE_BASKET_ITEM,
        index:index
    };
};

export const sendRequest = (basketItems) => {
    const user = JSON.parse(localStorage.getItem('user'));
    let token = null;

    return dispatch => {
        if (user){
            token = user.token;
        }

        axios.post( 'sendRequest.php', {"jwt":token, "basketItems":basketItems} )
            .then( response => {
                dispatch(requestSuccess(response.data));
            })
            .catch( error => {
                let txt = "Something went wrong";
                if (error && error.response.data && error.response.data.message){
                    txt = error.response.data.message
                }
                dispatch(showError(txt));
            });
    };
}; 

export const requestSuccess = (requestID) => {
    return {
        type:actionTypes.REQUEST_SUCCESSS,
        requestID:requestID
    };
};

export const showError = (message) => {
    return {
        type:actionTypes.SHOW_ERROR,
        message:message
    };
};