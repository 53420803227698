import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './SearchBox.module.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Spinner from '../../components/Spinner/Spinner';
import * as actions from '../../store/actions/index';

class SearchBox extends Component {
    
    state={
        searchResults:[],
        searchBoxValue:'',
        loading: false,
        showSearchResults: false
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);

        window.provider = new OpenStreetMapProvider();
        const form = document.getElementById("searchBoxInner");
        window.input = form.querySelector('input[type="text"]');
    }

    clearSearchBox = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({searchBoxValue:'', searchResults:[], showSearchResults:false});
        this.props.onClearSearchResultItem();
    }

    inputChanged = (e) => {
        this.setState({searchBoxValue:e.target.value})
        this.searchLocation();
    }

    searchButtonClicked = (e) => {
        e.preventDefault();
        this.searchLocation();
    }

    searchLocation = async e => {
        if (window.input.value.trim().length > 0){
            this.setState({ loading:true });

            const results = await window.provider.search({ query: window.input.value });

            if (results.length === 0){
                this.setState({loading: false, showSearchResults:true})
            } else {
                this.setState({searchResults:results, loading: false, showSearchResults:true})
            }
        }
    }

    handleClickOutside = (event) => {
        if (this.searchRef && !this.searchRef.contains(event.target)) {
            this.setState({showSearchResults:false});
        } else {
            if (this.state.searchResults.length > 0){
                this.setState({showSearchResults:true});
            }
        }
    }

    handleSearchItemClick(index){
        this.setState({searchBoxValue:this.state.searchResults[index].label, showSearchResults:false})
        this.props.onShowSearchResultItem(this.state.searchResults[index])
    }

    render () {
        return(
            <div 
                ref={ref => this.searchRef = ref}
                className={this.state.showSearchResults ? [classes.SearchBoxWrapper, classes.ShowSearchResults].join(' ') : classes.SearchBoxWrapper} 
                role="search">

                <form id="searchBoxInner" className={classes.SearchBoxInner}>
                    <input 
                        id="searchBox" 
                        className={classes.SearchBox} 
                        placeholder="Search places" 
                        autoComplete="off"
                        type="text" 
                        onChange={this.inputChanged}
                        value={this.state.searchBoxValue}/>
                    <div className={classes.SearchButtonWrapper}>
                        <button type="submit" className={classes.SearchButton} onClick={this.searchButtonClicked}></button>
                    </div>
                    <div className={classes.ClearSearchButtonWrapper}>
                        {this.state.loading ?
                            <Spinner />
                            :
                            <button className={classes.ClearSearchButton} onClick={this.clearSearchBox}></button>
                        }
                    </div>
                </form>
                {this.state.showSearchResults ?
                    <div className={classes.ResultsWrapper}>
                        <ul className={classes.ResultsItems}>
                            <li className={this.state.searchResults.length > 0 ? [classes.NoResultItem, classes.Hide].join(' ') : classes.NoResultItem}>No results were found</li>
                            {this.state.searchResults.map((result, idx) => 
                                <li 
                                    key={"result_" + idx} 
                                    className={classes.ResultItem}
                                    onClick={() => this.handleSearchItemClick(idx)}>

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                                    <span>{result.label}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeDataButton: state.content.activeDataButton,
        activeToolButton: state.content.activeToolButton,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowSearchResultItem: (searchResultItem) => dispatch(actions.showSearchResultItem(searchResultItem)),
        onClearSearchResultItem: () => dispatch(actions.clearSearchResultItem())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
