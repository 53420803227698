import * as actionTypes from './actionTypes';
import axios from '../../axios-api';
import {getSentinelImages} from './content';

export const removeFileFromFileList = (index) => {
    return {
        type:actionTypes.REMOVE_FILE_FROM_FILE_LIST,
        index:index
    };
};

export const fileAddedToList = (event) => {
    event.persist();
    return {
        type:actionTypes.FILE_ADDED_TO_LIST,
        event:event
    };
};

export const fileDropedToList = (fileList) => {
    return {
        type:actionTypes.FILE_DROPED_TO_LIST,
        fileList:fileList
    };
};

export const uploadFilesFailed = () => {
    return {
        type: actionTypes.UPLOAD_FILES_FAILED
    };
};

export const uploadProgress = (percentCompleted, fileIndex) => {
    return {
        type: actionTypes.UPLOAD_PROGRESS,
        percentCompleted: percentCompleted,
        fileIndex: fileIndex
    };
}

export const clearFilesList = () => {
    return {
        type: actionTypes.CLEAR_FILES_LIST
    };
};

export const saveAOIUpload = (uploadedItem) => {
    return {
        type: actionTypes.SAVE_AOI_UPLOAD,
        uploadedItem: uploadedItem
    };
};

export const uploadFiles = () => {
    return (dispatch, getState) => {
        const filesList = getState().dropzone.filesList;

        for (let i = 0; i < filesList.length; i++){
            let formData = new FormData();
            formData.append('file', filesList[i]);
            
            const config = {
                onUploadProgress: function(progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    dispatch(uploadProgress(percentCompleted, i));
                }
            }

            axios.post( 'upload.php', formData, config)
                .then( response => {
                    dispatch(saveAOIUpload(response.data));
                    dispatch(getSentinelImages(parseInt(response.data.id)));
                })
                .catch( error => {
                    // dispatch(alertError(error.response.data.message));
                    // dispatch(uploadFilesFailed());
                });
        }
    };
};