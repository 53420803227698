import React from 'react';

import classes from './Layout.module.css';
import Sidebar from '../../components/Navigation/Sidebar/Sidebar';
import Topbar from '../../components/Navigation/Topbar/Topbar';

const layout = ( props ) => (
    <div className={classes.Wrapper}>     
        <Sidebar />
        <div className={classes.FlexCol}>
            <Topbar />
            <main className={classes.ContentWrapper}>
                {props.children}
            </main>
        </div>
    </div>
)

export default layout;