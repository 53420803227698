import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import classes from './Topbar.module.css';
import LoginRegister from '../../../containers/LoginRegister/LoginRegister';
import LoginButton from '../../../components/UI/Buttons/LoginButton';
import * as actions from '../../../store/actions/index';

class Topbar extends Component {

    componentDidMount () {
        this.props.onGetUserFromLocalStorage();
    }

    logout = () => {
        this.props.onLogoutUser();
        if (this.props.location.pathname === "/admin"){
            this.props.history.push('/');
        }
    }

    render(){
        return(
            <div className={classes.Topbar}>
                {this.props.loggedIn ? 
                    <div className={classes.Welcome}>
                        <span>Welcome</span>
                        <span className={classes.WelcomeUsername}>{this.props.loggedInUser.username}</span>
                        <LoginButton 
                            logout
                            clicked={this.logout}
                            >
                            <div className={classes.Logout}>
                                <span>Log out</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"/>
                                </svg>
                            </div>
                        </LoginButton>
                    </div>
                :
                    <div className={classes.LoginRegisterButtons}>
                        <LoginRegister />
                    </div>
                }
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        loggedIn: state.loginRegister.loggedIn,
        loggedInUser: state.loginRegister.loggedInUser
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetUserFromLocalStorage: () => dispatch(actions.getUserFromLocalStorage()),
        onLogoutUser: () => dispatch(actions.logOut()),
        onShowLoginRegister: (type) => dispatch(actions.showLoginRegister(type))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));