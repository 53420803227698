import React, { Component } from 'react';
import { Map, FeatureGroup, TileLayer, GeoJSON } from 'react-leaflet';

import classes from './MapAsImage.module.css';

class MapAsImage extends Component {

    render () {
        return(
            <Map 
                className={classes.AOIMap}
                ref={ref => this.aoiMapRef = ref}
                zoomControl={false}
                bounds={this.props.aoi.bounds}
                dragging={false}
                touchZoom={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}>

                {this.props.showBackground ?
                    <TileLayer 
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                    />
                    : null
                }
                <FeatureGroup 
                    ref="drawFeatures">
                    <GeoJSON 
                        data={this.props.aoi}
                        fillColor="#b6b823"
                        fillOpacity="0.5" 
                        weight="2"
                        color="#b6b823" 
                        dashArray="5 5" 
                    />
                </FeatureGroup>
            </Map>
        );
    }
}

export default MapAsImage;