import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-side-by-side";
import classes from "./Swipe.module.css";

const CONFIG = require('../../reactConfig.json');

const Swipe = (props) => {
    let [swipeMap, setSwipeMap] = useState();
    let [layerLeft, setLayerLeft] = useState();
    let [layerRight, setLayerRight] = useState();
    let [sideBySide, setsideBySide] = useState();

    useEffect(() => {
        const phpUrl = CONFIG.phpScripts;

        if (!swipeMap){
            swipeMap = new L.map("swipeMap", { zoomControl: false });
            swipeMap.fitBounds(props.bounds);
            L.tileLayer(props.basemapUrl).addTo(swipeMap); // Background layer
            setSwipeMap(swipeMap);
        }

        // Layer left
        if (layerLeft){
            swipeMap.removeLayer(layerLeft)
        }
            
        layerLeft = L.tileLayer.wms(phpUrl + "proxy.php/wms?", {
            request: "GetSwipe",
            layers: props.leftImage.geoserver_layer,
            transparent: true,
            format: 'image/png'
        }).addTo(swipeMap);

        setLayerLeft(layerLeft)

        // Layer right
        if (layerRight){
            swipeMap.removeLayer(layerRight)
        }
        
        layerRight = L.tileLayer.wms(phpUrl + "proxy.php/wms?", {
            request: "GetSwipe",
            layers: props.rightImage.geoserver_layer,
            transparent: true,
            format: 'image/png'
        }).addTo(swipeMap);

        setLayerRight(layerRight)

        // side by side
        if (!sideBySide){
            sideBySide = L.control.sideBySide(layerLeft, layerRight).addTo(swipeMap);
        }

        sideBySide.setLeftLayers(layerLeft);
        sideBySide.setRightLayers(layerRight);
        
        setsideBySide(sideBySide)
        
        const printButtons = document.getElementsByClassName('easyPrintSizeMode');
        if (printButtons.length === 0){
            L.easyPrint({
                position: 'bottomright',
                sizeModes: ['A4Portrait', 'A4Landscape']
            }).addTo(swipeMap);
        }

    }, [props.basemapUrl,props.bounds,props.leftImage.geoserver_layer,props.rightImage.geoserver_layer]);

    return <div id="swipeMap" className={classes.SwipeMap} />;
};

export default Swipe;