import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import classes from './Sidebar.module.css';
import Logo from '../../Logo/Logo';
import MenuButton from '../../UI/Buttons/MenuButton';
import SearchBox from '../../../containers/SearchBox/SearchBox';
import SidebarHandle from '../../SidebarHandle/SidebarHandle';
import * as actions from '../../../store/actions/index';

class Sidebar extends Component {

    // If in drawing mode, then cancel drawing before changing tab
    handleDataButtonClick(index){
        if (this.props.drawing){
            this.props.onClearDrawCoords();
            this.props.onToggleDrawing(false);
        }
        if (this.props.swipe && index !== 0){
            this.props.onClearDrawCoords();
            this.props.onToggleSwipe();
        }

        // If admin button was pushed, go to admin
        if (index === 4){
            this.props.history.push('/admin');

        // If from the admin location, another menu button was clicked, goto home
        } else if (this.props.location.pathname === "/admin"){
            this.props.history.push('/');
        }
                
        this.props.onClickDataButton(index);
    }

    render () {
        return(
            <div className={this.props.sideBarVisible ? [classes.Sidebar, classes.Visible].join(" ") : classes.Sidebar}>
                                
                <SidebarHandle 
                    open={this.props.sideBarVisible}
                    clicked={() => this.props.onToggleSidebar()} 
                />

                <div className={classes.SidebarInner}>
                    <SearchBox />
                    <nav className={classes.Sidenav}>
                        <MenuButton
                            active= {this.props.activeDataButton === 0 ? true : false}
                            fontColor="dark"
                            clicked={() => this.handleDataButtonClick(0)}>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z"></path></svg>
                            <span>TOOLS</span>
                        </MenuButton>

                        <MenuButton
                            active= {this.props.activeDataButton === 1 ? true : false}
                            fontColor="dark"
                            clicked={() => this.handleDataButtonClick(1)}>
                                
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z"></path></svg>
                            <span>DATA</span>
                        </MenuButton>

                        <MenuButton
                            active= {this.props.activeDataButton === 2 ? true : false}
                            fontColor="dark"
                            clicked={() => this.handleDataButtonClick(2)}>
                                
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"></path></svg>
                            <span>PRODUCTS</span>
                        </MenuButton>

                        <MenuButton
                            active= {this.props.activeDataButton === 3 ? true : false}
                            fontColor="dark"
                            clicked={() => this.handleDataButtonClick(3)}>
                                
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                            <span>DOWNLOADS</span>
                        </MenuButton>

                        {this.props.loggedInUser && this.props.loggedInUser.role === "Admin" ?
                            <MenuButton
                                active= {this.props.activeDataButton === 4 ? true : false}
                                fontColor="dark"
                                clicked={() => this.handleDataButtonClick(4)}>
                                    
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M610.5 373.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 400.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm201.2 226.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9 4.6c-6 3.4-12.8 5.3-19.6 5.3-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-5.5-17.7 1.9-36.4 17.9-45.7l7.9-4.6c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7.9-2.9 2.2-5.8 3.2-8.7-3.8-.3-7.5-1.2-11.4-1.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c10.1 0 19.5-3.2 27.2-8.5-1.2-3.8-2-7.7-2-11.8v-9.2z"></path></svg>
                                <span>ADMIN</span>
                            </MenuButton>
                            : null
                        }
                    </nav>

                    <Logo />
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        activeDataButton: state.content.activeDataButton,
        sideBarVisible: state.content.sideBarVisible,
        drawing: state.content.drawing,
        loggedIn: state.loginRegister.loggedIn,
        loggedInUser: state.loginRegister.loggedInUser,
        swipe: state.content.swipe
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onClickDataButton: (index) => dispatch(actions.clickDataButton(index)),
        onToggleSidebar: () => dispatch(actions.toggleSidebar()),
        onClearDrawCoords: () => dispatch(actions.clearDrawCoords()),
        onToggleDrawing: () => dispatch(actions.toggleDrawing()),
        onToggleSwipe: () => dispatch(actions.toggleSwipe())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));