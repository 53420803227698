import * as actionTypes from '../actions/actionTypes';
import L from 'leaflet';
import {updateObject, arrayMove} from '../utilities';

const initialState = {
    mapBounds: [[39.1, 45.3],[42.7, 51.1]],
    mapLayers:[],
    mapLayersRaster:[],
    tableData: null,
    searchResultItem: null,
    mapLayersLoading:{},
    queryMapLayers:[],
    queryMapLayersLoading:{},
    mapKey:null,
    grazingLayers:[],
    grazingYears:[]
};

const updateMapBounds = (state, action) => {
    return updateObject(state, {
        mapBounds:action.mapBounds
    });
}

const updateLayerVisibility = (state, action) => {
    let newLayers = [...state[action.collection]];
    newLayers[action.layerIndex].visible = !newLayers[action.layerIndex].visible;

    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const updateLayerFillColor = (state, action) => {
    const r = action.fillColor.r
    const g = action.fillColor.g
    const b = action.fillColor.b
    const a = action.fillColor.a

    let newLayers = [...state[action.collection]];
    newLayers[action.layerIndex].style.fillColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";

    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const updateLayerBorderColor = (state, action) => {
    const r = action.borderColor.r
    const g = action.borderColor.g
    const b = action.borderColor.b
    const a = action.borderColor.a

    let newLayers = [...state[action.collection]];
    newLayers[action.layerIndex].style.color = "rgba(" + r + "," + g + "," + b + "," + a + ")";

    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const updateLayerBorderWidth = (state, action) => {
    let newLayers = [...state[action.collection]];
    newLayers[action.layerIndex].style.weight = action.event.target.value;
 
    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const updateMapLayersOrder = (state,action) => {
    let newLayers = [...state[action.collection]];
    arrayMove(newLayers, action.event.oldIndex, action.event.newIndex);

    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const updateMapTable = (state, action) => {
    const tableData = state[action.collection][action.layerIndex].data;

    return updateObject(state,{
        tableData: tableData
    });
}

const removeLayerItem = (state, action) => {
    let newLayers = [...state[action.collection]];
    newLayers.splice(action.layerIndex, 1);

    return updateObject(state,{
        [action.collection]:newLayers
    });
}

const showSearchResultItem = (state, action) => {
    return updateObject(state,{
        searchResultItem:action.searchResultItem,
        mapBounds:[[action.searchResultItem.y,action.searchResultItem.x],[action.searchResultItem.y,action.searchResultItem.x]]
    });
}

const clearSearchResultItem = (state) => {
    return updateObject(state,{
        searchResultItem:null
    });
}

const updateLayerViewparams = (state,action) => {
    let mapLayers = [...state.mapLayers];
    mapLayers[action.mapLayerIndex].viewparams = action.viewparams;

    return updateObject(state, {mapLayers:mapLayers});
}

const addLayerLoading = (state,action) => {
    let layersLoading = {...state[action.collection]};
    layersLoading[action.uniqueId]=true;

    return updateObject(state, {[action.collection]:layersLoading});
}

const removeLayerLoading = (state,action) => {
    let layersLoading = {...state[action.collection]};
    delete layersLoading[action.uniqueId];

    return updateObject(state, {[action.collection]:layersLoading});
}

const getMapLayersStarted = (state) => {
    return updateObject(state, {loading:true});
}

const getMapLayersSuccess = (state,action) => {
    let mapLayersRaster = [];
    let grazingYears = [];
    let mapLayers = [];
    let mapLayersLabels = {
        "adm0": "Countries",
        "adm1": "Provinces",
        "adm2": "Districts",
        "srtm_30": "SRTM DEM (30m)",
        "roads": "Road network",
        "hydrography": "Hydrography",
        "lulc": "Land use / Land cover",
        "pastures": "Pastures"
    }

    action.mapLayers.map_layers.forEach(mapLayer => {
        let geoserver_layer = mapLayer.gmdfileIdentifier.gcoCharacterString;
        // let name = mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdcitation.gmdCI_Citation.gmdtitle.gcoCharacterString;
        let name = mapLayer.gmdfileIdentifier.gcoCharacterString.split(":")[1];
        // let label = mapLayersLabels[name] || name;
        let label = mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdcitation.gmdCI_Citation.gmdtitle.gcoCharacterString;
        let data_group_id = 2;
        let data_subgroup_id = 0;
        let data_format = "Shapefile";
        let raster_filename = name + ".tif";
        let units = "";
        let tempLayer = {};
        let style = "";
        let keywords;
        let keywordsString = "";
        let downloads = false;
        let downloadLink = "";
        
        if (name.toLowerCase().indexOf("srtm_30") !== -1) {data_group_id = 2; data_subgroup_id = 0; units = "meters"; style = "dem"; data_format = "Raster"; downloads = true;}
        else if (name.toLowerCase().indexOf("adm0") !== -1) {data_group_id = 3; data_subgroup_id = 1; style = "countries"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("adm1") !== -1) {data_group_id = 3; data_subgroup_id = 1; style = "provinces"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("adm2") !== -1) {data_group_id = 3; data_subgroup_id = 1; style = "districts"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("roads") !== -1) {data_group_id = 3; data_subgroup_id = 1; style = "roads"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("lulc") !== -1) {data_group_id = 3; data_subgroup_id = 2; style = "lulc"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("pastures") !== -1) {data_group_id = 3; data_subgroup_id = 2; style = "pastures"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("hydrography") !== -1) {data_group_id = 3; data_subgroup_id = 2; style = "hydrography"; raster_filename = "";}
        else if (name.toLowerCase().indexOf("sentinel2") !== -1) {data_group_id = 4; data_subgroup_id = 0; style = "raster";}

        else if (name.startsWith("sys_") === false && geoserver_layer) {
            data_group_id = 5; 
            data_format = "Raster";
            
            if (name.toLowerCase().indexOf("grazing_capacity_cattle") !== -1) {data_subgroup_id = 7; units = "cattle / month / ha"; style = "grazing_capacity_cows_style"; downloads=true}
            else if (name.toLowerCase().indexOf("grazing_capacity_sheep") !== -1) {data_subgroup_id = 7; units = "sheep / month / ha"; style = "grazing_capacity_sheeps_style"; downloads=true}
            else if (name.toLowerCase().indexOf("pasture_condition") !== -1) {data_subgroup_id = 6; style="pasture_condition_style"; downloads=true}
            else if (name.toLowerCase().indexOf("pasture_productivity") !== -1) {data_subgroup_id = 4; units = "kg / ha"; style="pasture_productivity_style"; downloads=true}
            else if (name.toLowerCase().indexOf("swir2_re1") !== -1) {data_subgroup_id = 3; style="raster"; downloads=true}
            else if (name.toLowerCase().indexOf("swir1_re1") !== -1) {data_subgroup_id = 3; style="raster"; downloads=true}
            else if (name.toLowerCase().indexOf("sentinel_ndwi") !== -1) {data_subgroup_id = 3; style="sentinel_NDWI_style"; downloads=true}
            else if (name.toLowerCase().indexOf("sentinel_ndvi") !== -1) {data_subgroup_id = 3; style="sentinel_NDVI_style"; downloads=true}
            else if (name.toLowerCase().indexOf("sentinel_nli") !== -1) {data_subgroup_id = 3; style="sentinel_NLI_style"; downloads=true}
        }

        if (downloads === true){
            downloadLink = mapLayer.gmddistributionInfo.gmdMD_Distribution.gmdtransferOptions.gmdMD_DigitalTransferOptions.gmdonLine[1].gmdCI_OnlineResource.gmdlinkage.gmdURL;
        }

        if (name && name.startsWith("sys_") === false && data_group_id !== 4){
            keywords = mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmddescriptiveKeywords.gmdMD_Keywords.gmdkeyword;
            keywords.forEach(keyword => {
                keywordsString = keywordsString + keyword.gcoCharacterString + ","
            });
            keywordsString = keywordsString.slice(0, -1); // remove last comma

            tempLayer = {
                geoserver_layer:mapLayer.gmdfileIdentifier.gcoCharacterString,
                name:name,
                label: label,
                date:mapLayer.gmddateStamp.gcoDate,
                description:'', // Change to get from csw
                // creator:{
                //     organization:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdorganisationName.gcoCharacterString,
                //     individual:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdindividualName.gcoCharacterString,
                //     contactInfo:{
                //         country:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdcontactInfo.gmdCI_Contact.gmdaddress.gmdCI_Address.gmdcountry.gcoCharacterString,
                //         city:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdcontactInfo.gmdCI_Contact.gmdaddress.gmdCI_Address.gmdcity.gcoCharacterString,
                //         pc:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdcontactInfo.gmdCI_Contact.gmdaddress.gmdCI_Address.gmdpostalCode.gcoCharacterString,
                //         email:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdcontactInfo.gmdCI_Contact.gmdaddress.gmdCI_Address.gmdelectronicMailAddress.gcoCharacterString,
                //         telephone:mapLayer.gmdcontact.gmdCI_ResponsibleParty.gmdcontactInfo.gmdCI_Contact.gmdphone.gmdCI_Telephone.gmdvoice.gcoCharacterString
                //     }
                // },
                bounds:[
                    [
                        mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdextent.gmdEX_Extent.gmdgeographicElement.gmdEX_GeographicBoundingBox.gmdsouthBoundLatitude.gcoDecimal,
                        mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdextent.gmdEX_Extent.gmdgeographicElement.gmdEX_GeographicBoundingBox.gmdwestBoundLongitude.gcoDecimal
                    ],
                    [
                        mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdextent.gmdEX_Extent.gmdgeographicElement.gmdEX_GeographicBoundingBox.gmdnorthBoundLatitude.gcoDecimal,
                        mapLayer.gmdidentificationInfo.gmdMD_DataIdentification.gmdextent.gmdEX_Extent.gmdgeographicElement.gmdEX_GeographicBoundingBox.gmdeastBoundLongitude.gcoDecimal
                    ]
                ],
                publisher:'', // Change to get from csw
                language:'', // Change to get from csw
                coordinate_system:'', // Change to get from csw
                units:units, // Change to get from csw
                downloads:downloads,
                data_group_id:data_group_id,
                data_subgroup_id:data_subgroup_id,
                keywords:keywordsString,
                data_format: data_format,
                raster_filename: raster_filename, 
                layerOpacity:1,
                connection:'wms',
                viewparams:'',
                style:style,
                downloadLink: downloadLink,
                private: mapLayer.private,
                uniqueId: '_' + Math.random().toString(36).substring(2, 15)
            }

            mapLayers.push(tempLayer)
        }

        if (data_format === 'Raster' && name.startsWith("stats_") === false){
            mapLayersRaster.push(tempLayer);
        }

        // Insert all available years for the user for grazing in an array
        if (data_subgroup_id === 7){
            let year = name.split('_')[4].substring(0, 4); //TODO: this should change if the naming convention changes
            let yearIndex = grazingYears.findIndex(grazingYear => grazingYear.value === year);

            if (yearIndex === -1){ //the year has not been added to the array
                grazingYears.push({value:year, label:year});
            }
        }
    });

    // sort array with years ascending
    grazingYears.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    
    return updateObject(state, {
        error:false,
        //loading:false,
        mapLayers:mapLayers,
        mapLayersRaster:mapLayersRaster,
        grazingYears:grazingYears
    });
}


const getMapLayersFailed = (state) => {
    return updateObject(state, {error:true,loading:false});
}

const updateMapLayersStarted = (state) => {
    return updateObject(state, {loading:true});
}

const updateMapLayersSuccess = (state,action) => {
    return updateObject(state, {
        error:false,
        loading:false,
        mapLayers:action.mapLayers.map_layers
    });
}

const updateMapLayersFailed = (state) => {
    return updateObject(state, {error:true,loading:false});
}

const saveQueryResultSuccess = (state,action) => {
    let queryMapLayers = [...state.queryMapLayers];

    let boundsLayer = JSON.parse(action.boundingBox.features[0].properties.bounds);
    let bounds = L.geoJSON(boundsLayer).getBounds();

    const layer = action.payload.selectedQueryLayer.tableName;

    let filter = "";
    let name = "";

    if (action.payload.selectedQueryField.dataType === "string"){
        filter = action.payload.selectedQueryField.field + "='" + action.payload.selectedQueryValue + "'";
        name = action.payload.selectedQueryLayer.label + ": " + action.payload.selectedQueryField.field + " = " + action.payload.selectedQueryValue;
    } else {
        let min = action.payload.selectedQueryValue[0];
        let max = action.payload.selectedQueryValue[1];

        filter = "(" + action.payload.selectedQueryField.field + ">=" + min + " AND " + action.payload.selectedQueryField.field + "<=" + max + ")";
        name = action.payload.selectedQueryLayer.label + ": " + min + " <= " + action.payload.selectedQueryField.field + " >= " + max;
    }
    
    queryMapLayers.push(
        {
            geoserver_layer: "senspa:" + layer,
            name: name,
            label: name,
            bounds: bounds,
            downloads: false,
            data_group_id: null,
            data_subgroup_id: null,
            group: "queries",
            connection: "wms",
            visible: true,
            viewparams: "",
            filter: filter,
            layerOpacity: 1,
            type: "raster",
            style: "",
            uniqueId: '_' + Math.random().toString(36).substring(2, 15)
        }
    )

    return updateObject(state, {queryMapLayers:queryMapLayers});
}

const updateMapKey = (state) => {
    var d = new Date();

    return updateObject(state, {mapKey:d.getMilliseconds()});
}

const changeLayerOpacity = (state, action) => {
    let layers = [...state[action.collection]];
    layers[action.layerIndex].layerOpacity = action.percentage / 100;

    return updateObject(state, {[action.collection]:layers});
}

const getSentinelImagesSuccess = (state, action) => {
    let mapLayers = [];
    let mapLayersNoSentinel = [];

    state.mapLayers.forEach(mapLayer => {
        if (mapLayer.data_group_id !== 4){
            mapLayersNoSentinel.push(mapLayer);
        }
    });

    mapLayers = [...mapLayersNoSentinel, ...action.sentinelImages];

    return updateObject(state, {mapLayers:mapLayers});
}

const clearSentinelImages = (state) => {
    let mapLayersNoSentinel = [];

    state.mapLayers.forEach(mapLayer => {
        if (mapLayer.data_group_id !== 4){
            mapLayersNoSentinel.push(mapLayer);
        }
    });

    return updateObject(state, {mapLayers:mapLayersNoSentinel});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_LAYER_FILL_COLOR: return updateLayerFillColor(state, action);
        case actionTypes.UPDATE_MAP_BOUNDS: return updateMapBounds(state, action);
        case actionTypes.UPDATE_LAYER_VISIBILITY: return updateLayerVisibility(state, action);
        case actionTypes.UPDATE_LAYER_BORDER_COLOR: return updateLayerBorderColor(state, action);
        case actionTypes.UPDATE_LAYER_BORDER_WIDTH: return updateLayerBorderWidth(state, action);
        case actionTypes.UPDATE_MAP_LAYERS_ORDER: return updateMapLayersOrder(state, action);
        case actionTypes.UPDATE_MAP_TABLE: return updateMapTable(state, action);
        case actionTypes.REMOVE_LAYER_ITEM: return removeLayerItem(state, action);
        case actionTypes.SHOW_SEARCH_RESULT_ITEM: return showSearchResultItem(state, action);
        case actionTypes.CLEAR_SEARCH_RESULT_ITEM: return clearSearchResultItem(state);
        case actionTypes.UPDATE_LAYER_VIEWPARAMS: return updateLayerViewparams(state,action);
        case actionTypes.ADD_LAYER_LOADING: return addLayerLoading(state,action);
        case actionTypes.REMOVE_LAYER_LOADING: return removeLayerLoading(state,action);
        case actionTypes.GET_MAP_LAYERS_STARTED: return getMapLayersStarted(state);
        case actionTypes.GET_MAP_LAYERS_SUCCESS: return getMapLayersSuccess(state,action);
        case actionTypes.GET_MAP_LAYERS_FAILED: return getMapLayersFailed(state);
        case actionTypes.UPDATE_MAP_LAYERS_STARTED: return updateMapLayersStarted(state);
        case actionTypes.UPDATE_MAP_LAYERS_SUCCESS: return updateMapLayersSuccess(state,action);
        case actionTypes.UPDATE_MAP_LAYERS_FAILED: return updateMapLayersFailed(state);
        case actionTypes.SAVE_QUERY_RESULT_SUCCESS: return saveQueryResultSuccess(state,action);
        case actionTypes.UPDATE_MAP_KEY: return updateMapKey(state,action);
        case actionTypes.CHANGE_LAYER_OPACITY: return changeLayerOpacity(state,action);
        case actionTypes.GET_SENTINEL_IMAGES_SUCCESS: return getSentinelImagesSuccess(state,action);
        case actionTypes.CLEAR_SENTINEL_IMAGES: return clearSentinelImages(state);
        default: return state;
    }
};

export default reducer;