import * as actionTypes from '../actions/actionTypes';
import L from 'leaflet';
import {updateObject} from '../utilities';

const initialState = {
    error: false,
    errorMessage: "",
    loading:false,
    basemapLayers:[
        {
            name:'satellite',
            label:'Google Satellite',
            maxZoom: 20,
            isGoogle: true,
            googlekey:'AIzaSyB7wxoH28aEIcYKWxUt7vmGrJ_zFKdZosU'
        },
        {
            name:'roadmap',
            label:'Google Roadmap',
            maxZoom: 20,
            isGoogle: true,
            googlekey:'AIzaSyB7wxoH28aEIcYKWxUt7vmGrJ_zFKdZosU'
        },
        {
            name:'terrain',
            label:'Google Terrain',
            maxZoom: 20,
            isGoogle: true,
            googlekey:'AIzaSyB7wxoH28aEIcYKWxUt7vmGrJ_zFKdZosU'
        },
        {
            name:'OpenStreetMap_Mapnik',
            label:'Open Street Map',
            url:'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            maxZoom: 19,
            isGoogle: false
        },
        {
            name:'CartoDB_Positron',
            label:'CartoDB Positron',
            url:'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            subdomains: 'abcd',
            maxZoom: 19,
            isGoogle: false
        },
        {
            name:'Esri_WorldImagery',
            label:'Esri World Imagery',
            url:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            maxZoom: 15,
            isGoogle: false
        },
    ],
    selectedBasemap: 5,
    activeDataButton:null,
    sideBarVisible:true,
    drawing:false,
    mapLayerCoords:[],
    aoi:null,
    uploadedFileId: null,
    panelFullWidth:false,
    panelFullheight:false,
    swipe: false,
    syncMapBounds:null,
    loadingFeatureInfo: false,
    showPopup:false,
    featureInfoLayers: [],
    currentFeatureInfoLayer: 1,
    admOptions:{
        country:[],
        province:[],
        district:[]
    },
    vectorLayers:[],
    queryLayers:[],
    queryFields:[],
    queryValues:[],
    selectedQueryLayer:null,
    selectedQueryField:null,
    selectedQueryValue:null,
    leftSwipemapLayerIndex:0,
    rightSwipemapLayerIndex:1,
    statistics:{},
    statsLayerName:"srtm_30.tif",
    statsLoading:false,
    grazingStatsLoading:false,
    grazingStats:{cattle:null,sheep:null},
    productValue:null
}

const clickDataButton = (state, action) => {
    let activeDataButton = action.index
    if (state.activeDataButton === action.index){
        activeDataButton = null;
    }
    return updateObject(state, {activeDataButton:activeDataButton});
}

const toggleSidebar = (state) => {
    return updateObject(state, {sideBarVisible:!state.sideBarVisible});
}

const saveAOISuccess = (state,action) => {
    let geojson = L.geoJSON(action.aoiPolygon).getLayers()[0].feature;
    geojson.bounds = L.geoJSON(action.aoiPolygon).getBounds();

    return updateObject(state, {aoi:geojson, mapLayerCoords:[], uploadedFileId:parseInt(action.uploadedItemId.id)});
}

// TODO: Handle error, when the user uploads something other than polygon
const saveAOIUpload = (state,action) => {

    let geojson = null;
    let error = false;
    
    if (action.uploadedItem.geojson.features[0].geometry.type === "Polygon" || action.uploadedItem.geojson.features[0].geometry.type === "MultiPolygon"){
        geojson = action.uploadedItem.geojson;

        if (geojson.type === "FeatureCollection"){
            geojson = geojson.features[0]
        }
        geojson.bounds = L.geoJSON(geojson).getBounds();
                
    } else {
        error = true;
    }   

    return updateObject(state, {aoi:geojson, mapLayerCoords:[], error:error, uploadedFileId:action.uploadedItem.id});
}

const clearAOI = (state) => {
    return updateObject(state, {aoi:null, mapLayerCoords:[], uploadedFileId:null});
}

const toggleDrawing = (state, action) => {
    return updateObject(state, {drawing:action.value});
}

const addDrawPoint = (state, action) => {
    let mapLayerCoords = [...state.mapLayerCoords];
    mapLayerCoords.push(action.latlng);

    return updateObject(state, {mapLayerCoords:mapLayerCoords});
}

const removeDrawLastPoint = (state) => {
    let mapLayerCoords = [...state.mapLayerCoords];
    mapLayerCoords.pop();

    return updateObject(state, {mapLayerCoords:mapLayerCoords});
}

const clearDrawCoords = (state) => {
    return updateObject(state, {mapLayerCoords:[]});
}

const addPanelFullWidth = (state) => {
    return updateObject(state, {panelFullWidth:true});
}

const removePanelFullWidth = (state) => {
    return updateObject(state, {panelFullWidth:false});
}

const addPanelFullHeight = (state) => {
    return updateObject(state, {panelFullHeight:true});
}

const removePanelFullHeight = (state) => {
    return updateObject(state, {panelFullHeight:false});
}

const toggleSwipe = (state) => {
    return updateObject(state, {swipe:!state.swipe});
}

const getFeatureInfoStarted = (state) => {
    return updateObject(state, {loadingFeatureInfo:true, showPopup:true});
}

const getFeatureInfoSuccess = (state,action) => {
    let featureInfoLayers = [];

    action.layersData.forEach((layerData, index) => {
        if (layerData.data.features && layerData.data.features.length > 0){
            featureInfoLayers.push({layer:action.layers[index].layerName, layerData:layerData, layerLabel:action.layers[index].layerLabel})
        }
    });

    return updateObject(state, {loadingFeatureInfo:false, featureInfoLayers:featureInfoLayers});
}

const closePopup = (state) => {
    return updateObject(state, {loadingFeatureInfo:false, showPopup:false});
}

const changeFeaturePanel = (state,action) => {
    let currentFeatureInfoLayer = state.currentFeatureInfoLayer

    if (action.action === "add"){
        currentFeatureInfoLayer = currentFeatureInfoLayer + 1;
    } else {
        currentFeatureInfoLayer = currentFeatureInfoLayer - 1;
    }

    return updateObject(state, {currentFeatureInfoLayer:currentFeatureInfoLayer});
}

const getVectorLayersSuccess = (state,action) => {
    let queryLayers = [];
    action.vectorLayers.forEach(vectorLayer => {
        queryLayers.push({
            label:vectorLayer.label,
            value:vectorLayer.tableName
        })
    });

    return updateObject(state, {
        vectorLayers:action.vectorLayers, 
        queryLayers:queryLayers,
        queryFields:[],
        queryValues:[],
        selectedQueryLayer:null,
        selectedQueryField:null,
        selectedQueryValue:null
    });
}

const changeQueryLayer = (state,action) => {
    const selectedQueryLayer = state.vectorLayers.filter(x => x.tableName === action.layer)[0];

    let queryFields = [];
    selectedQueryLayer.fields.forEach(field => {
        queryFields.push({label:field.field, value:field.field})
    });

    return updateObject(state, {selectedQueryLayer:selectedQueryLayer, queryFields:queryFields, selectedQueryField:null, queryValues: [], selectedQueryValue:null});
}

const changeQueryField = (state,action) => {

    const selectedQueryField = state.selectedQueryLayer.fields.filter(x => x.field === action.field)[0];

    let queryValues = [];

    if (selectedQueryField.dataType === "string" || selectedQueryField.dataType === "boolean"){
        selectedQueryField.fieldValues.forEach(fieldValue => {
            queryValues.push({label:fieldValue, value:fieldValue})
        });
    }
    
    // TODO: When the field type is numeric
    else {
        queryValues=[];
    }
 
    return updateObject(state, {selectedQueryField:selectedQueryField, queryValues:queryValues});
}

const changeQueryValue = (state,action) => {
    return updateObject(state, {selectedQueryValue:action.value});
}

const updateSwipeImage = (state,action) => {
    return updateObject(state, {[action.position]:action.mapLayerIndex});
}

const getStatisticsStarted = (state) => {    
    return updateObject(state, {statsLoading:true});
}

const getStatisticsSuccess = (state,action) => {    
    return updateObject(state, {statistics:action.statistics.features[0].properties, statsLoading:false});
}

const getGrazingStatisticsStarted = (state) => {    
    return updateObject(state, {grazingStatsLoading:true});
}

const noGrazingAvailable = (state) => {
    const grazingStats = {cattle:null,sheep:null}

    return updateObject(state, {grazingStats:grazingStats});
}

//TODO: Right now it doesn't calculate grazing for multiple images
const getGrazingStatisticsSuccess = (state,action) => {   
    let grazingStats = {...state.grazingStats};
    let sheepCapacity = null;
    let cattleCapacity = null;

    if (action.statistics.features.length > 0){
        const area = action.statistics.features[0].properties.count * 0.01; // Number of pixels times the area of each pixel in hectares
        
        if (action.animal === "sheep"){
            sheepCapacity = Math.round(action.statistics.features[0].properties.mean * area);
            cattleCapacity = Math.round(action.statistics.features[0].properties.mean * area / 5);
        } else {
            sheepCapacity = Math.round(action.statistics.features[0].properties.mean * area * 5);
            cattleCapacity = Math.round(action.statistics.features[0].properties.mean * area);
        }
    }

    grazingStats.cattle = cattleCapacity;
    grazingStats.sheep = sheepCapacity;

    return updateObject(state, {grazingStatsLoading:false, grazingStats:grazingStats});
}

const updateStatsLayer = (state,action) => { 
    return updateObject(state, {statsLayerName:action.statsLayerName, statsLoading:false, statistics:{}});
}

const updateProductValue = (state,action) => { 
    return updateObject(state, {productValue:action.productValue});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CLICK_DATA_BUTTON: return clickDataButton(state, action);
        case actionTypes.TOGGLE_SIDEBAR: return toggleSidebar(state);
        case actionTypes.SAVE_AOI_SUCCESS: return saveAOISuccess(state,action);
        case actionTypes.SAVE_AOI_UPLOAD: return saveAOIUpload(state,action);
        case actionTypes.CLEAR_AOI: return clearAOI(state);
        case actionTypes.TOGGLE_DRAWING: return toggleDrawing(state, action);
        case actionTypes.ADD_DRAW_POINT: return addDrawPoint(state, action);
        case actionTypes.REMOVE_DRAW_LAST_POINT: return removeDrawLastPoint(state);
        case actionTypes.CLEAR_DRAW_COORDS: return clearDrawCoords(state);
        case actionTypes.ADD_PANEL_FULL_WIDTH: return addPanelFullWidth(state);
        case actionTypes.REMOVE_PANEL_FULL_WIDTH: return removePanelFullWidth(state);
        case actionTypes.ADD_PANEL_FULL_HEIGHT: return addPanelFullHeight(state);
        case actionTypes.REMOVE_PANEL_FULL_HEIGHT: return removePanelFullHeight(state);
        case actionTypes.TOGGLE_SWIPE: return toggleSwipe(state);
        case actionTypes.GET_FEATURE_INFO_STARTED: return getFeatureInfoStarted(state);
        case actionTypes.GET_FEATURE_INFO_SUCCESS: return getFeatureInfoSuccess(state,action);
        case actionTypes.CLOSE_POPUP: return closePopup(state);
        case actionTypes.CHANGE_FEATURE_PANEL: return changeFeaturePanel(state,action);
        case actionTypes.GET_VECTOR_LAYERS_SUCCESS: return getVectorLayersSuccess(state,action);
        case actionTypes.CHANGE_QUERY_LAYER: return changeQueryLayer(state,action);
        case actionTypes.CHANGE_QUERY_FIELD: return changeQueryField(state,action);
        case actionTypes.CHANGE_QUERY_VALUE: return changeQueryValue(state,action);
        case actionTypes.UPDATE_SWIPE_IMAGE: return updateSwipeImage(state,action);
        case actionTypes.GET_STATISTICS_STARTED: return getStatisticsStarted(state);
        case actionTypes.GET_STATISTICS_SUCCESS: return getStatisticsSuccess(state,action);
        case actionTypes.GET_GRAZING_STATISTICS_STARTED: return getGrazingStatisticsStarted(state);
        case actionTypes.GET_GRAZING_STATISTICS_SUCCESS: return getGrazingStatisticsSuccess(state,action);
        case actionTypes.NO_GRAZING_AVAILABLE: return noGrazingAvailable(state);
        case actionTypes.UPDATE_STATS_LAYER: return updateStatsLayer(state,action);
        case actionTypes.UPDATE_PRODUCT_VALUE: return updateProductValue(state,action);
        default: return state;
    }
};

export default reducer;