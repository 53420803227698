import React from 'react';

import classes from './LoginButton.module.css';

const loginButton = (props) => {
    return(
        <button
            disabled={props.disabled}
            value={props.value}
            className={classes.LoginButton}
            onClick={props.clicked}
        >
            {props.children}
        </button>
    )
};

export default loginButton;