import React from 'react';

import classes from './InfoButton.module.css';

const infoButton = (props) => {

    return(
        <div
            className={classes.InfoButton}
            onClick={props.clicked}
        >
            {props.children}
        </div>
    )
};

export default infoButton;