import * as actionTypes from './actionTypes';
import axiosRoot from 'axios';
import axios from '../../axios-api';

export const updateMapBounds = (mapBounds) => {
    return {
        type: actionTypes.UPDATE_MAP_BOUNDS,
        mapBounds: mapBounds
    };
}

export const updateLayerVisibility = (layerIndex, collection) => {
    return {
        type: actionTypes.UPDATE_LAYER_VISIBILITY,
        layerIndex: layerIndex,
        collection: collection
    };
};

export const updateLayerFillColor = (layerIndex, fillColor, collection) => {
    return {
        type: actionTypes.UPDATE_LAYER_FILL_COLOR,
        layerIndex:layerIndex,
        fillColor: fillColor,
        collection: collection
    };
};

export const updateLayerBorderColor = (layerIndex, borderColor, collection) => {
    return {
        type: actionTypes.UPDATE_LAYER_BORDER_COLOR,
        layerIndex:layerIndex,
        borderColor: borderColor,
        collection: collection
    };
};

export const updateLayerBorderWidth = (event, layerIndex, collection) => {
    event.persist();

    return {
        type: actionTypes.UPDATE_LAYER_BORDER_WIDTH,
        layerIndex:layerIndex,
        event: event,
        collection: collection
    };
};

export const updateMapLayersOrder = (event, collection) => {    
    return {
        type: actionTypes.UPDATE_MAP_LAYERS_ORDER,
        event:event,
        collection: collection
    };
};

export const updateMapTable = (layerIndex, collection) => {
    return {
        type: actionTypes.UPDATE_MAP_TABLE,
        layerIndex:layerIndex,
        collection: collection
    };
};

export const removeLayerItem = (layerIndex, collection) => {
    return {
        type: actionTypes.REMOVE_LAYER_ITEM,
        layerIndex:layerIndex,
        collection: collection
    };
};

export const showSearchResultItem = (searchResultItem) => {
    return {
        type: actionTypes.SHOW_SEARCH_RESULT_ITEM,
        searchResultItem:searchResultItem
    };
};

export const clearSearchResultItem = () => {
    return {
        type: actionTypes.CLEAR_SEARCH_RESULT_ITEM
    };
};

export const updateLayerViewparams = (mapLayerIndex,viewparams) => {
    return {
        type: actionTypes.UPDATE_LAYER_VIEWPARAMS,
        mapLayerIndex: mapLayerIndex,
        viewparams: viewparams
    };
};

export const addLayerLoading = (uniqueId, collection) => {
    return {
        type: actionTypes.ADD_LAYER_LOADING,
        uniqueId: uniqueId,
        collection: collection
    };
};

export const removeLayerLoading = (uniqueId, collection) => {
    return {
        type: actionTypes.REMOVE_LAYER_LOADING,
        uniqueId: uniqueId,
        collection: collection
    };
};

export const getMapLayers = () => {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem('user'));
        let token = null;

        if (user){
            token = user.token;
        }

        dispatch(getMapLayersStarted());
        axios.post( 'getMapLayers.php', {"jwt":token} )
            .then( response => {
                dispatch(getMapLayersSuccess(response.data));
            })
            .catch( error => {
                dispatch(getMapLayersFailed());
            });
    };
};

export const getMapLayersStarted = () => {
    return {
        type: actionTypes.GET_MAP_LAYERS_STARTED
    };
};

export const getMapLayersSuccess = (mapLayers) => {
    return {
        type: actionTypes.GET_MAP_LAYERS_SUCCESS,
        mapLayers:mapLayers
    };
};

export const getMapLayersFailed = () => {
    return {
        type: actionTypes.GET_MAP_LAYERS_FAILED
    };
};

export const saveQueryResult = (selectedQueryLayer,selectedQueryField,selectedQueryValue,geoserverLayers) => {
    return dispatch => {
        const payload={
            selectedQueryLayer:selectedQueryLayer,
            selectedQueryField:selectedQueryField,
            selectedQueryValue:selectedQueryValue,
            geoserverLayers:geoserverLayers
        }

        // let url = "";
        let min, max;
        if (selectedQueryField.dataType === "string"){
            min = selectedQueryValue;
            max = selectedQueryValue;
        } else {
            min = selectedQueryValue[0];
            max = selectedQueryValue[1];
        }

        const url = `proxy.php?&request=GetQuery&layerName=sys_${selectedQueryLayer.tableName}_bounds&viewparams=FIELD:${selectedQueryField.field};VALUE_MIN:${min};VALUE_MAX:${max};`;

        axios.get(url)
            .then( response => {
                dispatch(saveQueryResultSuccess(response.data, payload));
            })
            .catch( error => {
                
            });
    };
};

export const saveQueryResultSuccess = (boundingBox, payload) => {
    return {
        type: actionTypes.SAVE_QUERY_RESULT_SUCCESS,
        boundingBox:boundingBox,
        payload: payload
    };
};


export const updateMapKey = () => {
    return {
        type: actionTypes.UPDATE_MAP_KEY
    };
};

export const changeLayerOpacity = (layerIndex, percentage, collection) => {
    return {
        type: actionTypes.CHANGE_LAYER_OPACITY,
        layerIndex: layerIndex,
        percentage: percentage,
        collection: collection
    };
};

export const clearSentinelImages = () => {
    return {
        type: actionTypes.CLEAR_SENTINEL_IMAGES
    };
};
