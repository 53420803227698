import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const CONFIG = require('../../reactConfig.json');

const initialState = {
    error:false,
    errorMessage:"",
    phpUrl: CONFIG.phpScripts,
}

const showError = (state, action) => {
    return updateObject(state, {error:true,errorMessage:action.message});
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SHOW_ERROR: return showError(state, action);
        default: return state;
    }
};

export default reducer;