import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';

import classes from './LoginRegister.module.css';
import LoginButton from '../../components/UI/Buttons/LoginButton';
import Button from '../../components/UI/Buttons/Button';
import Spinner from '../../components/Spinner/Spinner';
import * as actions from '../../store/actions/index';

class LoginRegister extends Component {
    
    state={
        username: '',
        password: '',
        name_reg:'',
        email_reg:'',
        username_reg:'',
        password_reg:'',
        password_reg_confirm:'',

        checkTerms: false,
        password_reset:'',
        password_reset_confirm:'',
        email_reset:''
    }

    componentDidMount () {
        // If activation url -> activate account
        const activationKey = window.location.href.split('?')[1];
        if(activationKey) {
            if (activationKey.startsWith("reset")){
                this.props.onUpdatePassword(activationKey)
            } else {
                this.props.onAccountActivation(activationKey);
            }
        }
    }
        
    login(){
        if(this.state.username && this.state.password){
            this.props.onCheckLogin(this.state);
        }
    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    showModal(type){
        this.props.onShowLoginRegister(type)
        this.props.onAlertClear();

        this.setState({
            username: '',
            password: '',
            name_reg:'',
            email_reg:'',
            username_reg:'',
            password_reg:'',
            password_reg_confirm:'',
            checkTerms: false,
            password_reset:'',
            password_reset_confirm:'',
            email_reset:''
        });
    }

    hideModal(){
        this.props.onHideLoginRegister()
        this.props.onAlertClear();

        this.setState({
            username: '',
            password: '',
            name_reg:'',
            email_reg:'',
            username_reg:'',
            password_reg:'',
            password_reg_confirm:'',
            checkTerms: false,
            password_reset:'',
            password_reset_confirm:'',
            email_reset:''
        });

        // if (this.props.confirmationMessage) window.location.replace(this.props.baseUrl)
    }

    handleCheckTerms = () => {
        this.setState({checkTerms:!this.state.checkTerms});
    }

    checkRegister = () => {
        if (!this.state.name_reg || !this.state.email_reg || !this.state.username_reg || !this.state.password_reg || !this.state.password_reg_confirm){
            this.props.onAlertError("Please complete all fields");

        // } else if (!this.state.checkTerms){
        //     this.props.onAlertError("Please accept the terms of use");
        
        } else if (this.state.password_reg !== this.state.password_reg_confirm){
            this.props.onAlertError("The two passwords do not match");

        } else {
            this.props.onRegisterUser(this.state);
        }
    }

    checkResetPassword = () => {
        if (!this.state.password_reset || !this.state.password_reset_confirm || !this.state.email_reset){
            this.props.onAlertError("Please complete all fields");
        
        } else if (this.state.password_reset !== this.state.password_reset_confirm){
            this.props.onAlertError("The two passwords do not match");
        } 

        else {
            this.props.onSendResetPasswordEmail(this.state.email_reset, this.state.password_reset);
        }
    }

    render () {
        
        let page = <Aux>
                        <LoginButton 
                            register
                            clicked={() => this.showModal("register")}
                        >
                            <div className={classes.Register}>
                                <span>Register</span>
                            </div>
                        </LoginButton>

                        <LoginButton 
                            login
                            clicked={() => this.showModal("login")}
                        >
                            <div className={classes.Login}>
                                <span>Log in</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"/>
                                </svg>
                            </div>
                        </LoginButton>
                        
                        <div className={this.props.showModal ? [classes.LoginRegisterWrapper, classes.ShowModal].join(' ') : classes.LoginRegisterWrapper}>
                            
                            <div className={this.props.showModal ? [classes.Curtain, classes.Show].join(' ') : classes.Curtain}></div>

                            <div className={this.props.show === "login" ? [classes.LoginModalWrapper, classes.Show].join(' ') : classes.LoginModalWrapper}>
                                <div className={classes.CloseModal} onClick={() => this.hideModal()}>
                                    <span></span>
                                    <span></span>
                                </div>
                                <h1 className={classes.Heading}><span>LOGIN</span></h1>
                                
                                <div className={this.props.message !== "" ? [classes.Message, classes.ShowMessage, classes[this.props.type]].join(' ') : classes.Message}>{this.props.message}</div>
                                <div className={classes.LoginForm}>
                                    <div className={classes.InputWrapper}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                                        </div> 
                                        <input id="login_username" type="text" name="username" placeholder="Username" value={this.state.username} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                    </div>
                                    <div className={classes.InputWrapper}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>
                                        </div> 
                                        <input id="login_password" type="password" name="password" placeholder="Password" value={this.state.password} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                    </div>
                                    <div className={classes.Flexed}>
                                        <div className={classes.InputCheckWrapper}>
                                            <input type="checkbox" name="remember_me"/><span>Remember me</span>
                                        </div>
                                        <div className={classes.Link} onClick={() => this.showModal("changePassword")}>Forgot password?</div>
                                    </div>
                                    <input id="submit_login" type="submit" className={classes.SubmitLogin} value="Login" onClick={() => this.login()} />
                                    <div>
                                        <span>Don't have a password? </span>
                                        <span onClick={() => this.showModal("register")} className={classes.Link}>Register here.</span>
                                    </div>
                                </div>
                            </div>

                            <div className={this.props.show === "register" ? [classes.RegisterModalWrapper, classes.Show].join(' ') : classes.RegisterModalWrapper}>
                                <div className={classes.CloseModal} onClick={() => this.hideModal()}>
                                    <span></span>
                                    <span></span>
                                </div>
                                <h1 className={classes.Heading}><span>REGISTER</span></h1>
                                <span className={classes.Subheading}>Create your account. It's free and only takes a minute. </span>
                                
                                <div className={this.props.message !== "" ? [classes.Message, classes.ShowMessage, classes[this.props.type]].join(' ') : classes.Message}>{this.props.message}</div>
                                <div className={classes.RegisterForm}>
                                    <div className={[classes.Flexed,classes.Margin_b_30].join(' ')}>
                                        <div className={classes.InputWrapper}>
                                            <input type="text" name="name_reg" placeholder="Name" value={this.state.name_reg} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                        <div className={[classes.InputWrapper, classes.FlexRight].join(' ')}>
                                            <input type="text" name="email_reg" placeholder="Email" value={this.state.email_reg} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                    </div>

                                    <div className={classes.Flexed}>
                                        <div className={classes.InputWrapper}>
                                            <input type="text" name="username_reg" placeholder="Username" value={this.state.username_reg} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                    </div>
                                    <div className={[classes.Flexed, classes.Margin_b_10].join(' ')}>
                                        <div className={classes.InputWrapper}>
                                            <input type="password" name="password_reg" placeholder="Password" value={this.state.password_reg} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                        <div className={[classes.InputWrapper, classes.FlexRight].join(' ')}>
                                            <input type="password" name="password_reg_confirm" placeholder="Confirm password" value={this.state.password_reg_confirm} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                    </div>

                                    <div className={[classes.InputCheckWrapper, classes.AcceptTermsWrapper].join(' ')}>
                                        <input type="checkbox" name="accept_terms" onChange={this.handleCheckTerms} defaultChecked={this.state.checkTerms} />
                                        <span>I accept the</span><span className={classes.Link}>terms of use</span>
                                    </div>
                                    <div className={classes.SubmitRegister}>
                                        <Button
                                            clicked={this.checkRegister}
                                            style={{"background":"#82AE2C", "color":"#fff", "fontWeight":"600"}}
                                        >
                                            <div className={this.props.registering ? [classes.SpinnerWrapper, classes.Show].join(' ') : classes.SpinnerWrapper}>
                                                <Spinner themeLight />
                                            </div>
                                            <span>Register now</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className={this.props.show === "confirmation" ? [classes.ConfirmationModalWrapper, classes.Show].join(' ') : classes.ConfirmationModalWrapper}>
                                <div className={classes.CloseModal} onClick={() => this.hideModal()}>
                                    <span></span>
                                    <span></span>
                                </div>
                                <h1 className={classes.Heading}><span>Confirmation</span></h1>
                                
                                <div className={classes.ConfirmationMessage}>
                                    <span>{this.props.confirmationMessage}</span>
                                </div>
                            </div>

                            <div className={this.props.show === "changePassword" ? [classes.ChangePasswordModalWrapper, classes.Show].join(' ') : classes.ChangePasswordModalWrapper}>
                                <div className={classes.CloseModal} onClick={() => this.hideModal()}>
                                    <span></span>
                                    <span></span>
                                </div>
                                <h1 className={classes.Heading}><span>CHANGE PASSWORD</span></h1>
                                
                                <div className={this.props.message !== "" ? [classes.Message, classes.ShowMessage, classes[this.props.type]].join(' ') : classes.Message}>{this.props.message}</div>
                                
                                <div className={classes.ChangePasswordForm}>
                                    <div className={classes.Flexed}>
                                        <div className={classes.InputWrapper}>
                                            <input type="text" name="email_reset" placeholder="Email" value={this.state.email_reset.trim()} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                    </div>

                                    <div className={classes.Flexed}>
                                        <div className={classes.InputWrapper}>
                                            <input type="password" name="password_reset" placeholder="New Password" value={this.state.password_reset.trim()} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                        <div className={[classes.InputWrapper, classes.FlexRight].join(' ')}>
                                            <input type="password" name="password_reset_confirm" placeholder="Confirm new password" value={this.state.password_reset_confirm.trim()} onChange={(e) => this.onChange(e)} onBlur={() => this.props.onAlertClear()}/>
                                        </div>
                                    </div>

                                    <div className={classes.ChangePasswordButtons}>
                                        <input onClick={this.checkResetPassword} type="submit" className={classes.ChangePasswordButton} value="Submit new password" />
                                        <input onClick={() => this.showModal("login")} type="submit" className={[classes.ChangePasswordButton, classes.FlexRight].join(' ')} value="Cancel" />
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </Aux>

        return(
            page
        )
    }
}

const mapStateToProps = state => {
    return {
        showModal: state.loginRegister.showModal,
        show: state.loginRegister.show,
        message: state.alerts.message,
        type: state.alerts.type,
        confirmationMessage: state.loginRegister.confirmationMessage,
        registering: state.loginRegister.registering
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowLoginRegister: (type) => dispatch(actions.showLoginRegister(type)),
        onHideLoginRegister: () => dispatch(actions.hideLoginRegister()),
        onCheckLogin: (loginInfo) => dispatch(actions.checkLogin(loginInfo)),
        onRegisterUser: (registerInfo) => dispatch(actions.registerUser(registerInfo)),
        onAlertClear: () => dispatch(actions.alertClear()),
        onAlertError: (message) => dispatch(actions.alertError(message)),
        onAccountActivation: (activationKey) => dispatch(actions.accountActivation(activationKey)),
        onSendResetPasswordEmail: (email, password) => dispatch(actions.sendResetPasswordEmail(email, password)),
        onUpdatePassword: (activationKey) => dispatch(actions.updatePassword(activationKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegister);
