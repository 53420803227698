import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Dropzone.module.css';
import DropzoneButton from "../../components/UI/Buttons/DropzoneButton";
import * as actions from '../../store/actions/dropzone';
// import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
// import axios from '../../axios-api';

// TODO:
// 1) Use shapefile (in addition to zip), excel, txt (?)


class Dropzone extends Component {
    state = { 
        highlight: false,
        // disabled: false,
        // uploading: false,
        // uploadProgress: {},
        // successfullUploaded: false,
        allowedExtensions: [".zip", ".kml", ".xls", ".xlsx"]
    }
    
    openFileDialog = () => {
        this.fileInputRef.click();
    }

    onDragOver(evt) {
        evt.preventDefault();
        this.setState({ highlight: true });
    }
    
    onDragLeave() {
        this.setState({ highlight: false });
    }
    
    onDrop(evt) {
        evt.preventDefault();

        let newFilesList = [];
        let filename = "";
        let ext = "";

        // For multiple files
        // for (var i = 0; i < evt.dataTransfer.files.length; i++) {
        //     // Check if allowed extension
        //     filename = evt.dataTransfer.files[i].name;
        //     ext = "." + filename.substr(filename.lastIndexOf('.') + 1);

        //     if (this.state.allowedExtensions.includes(ext)){
        //         newFilesList.push(evt.dataTransfer.files.item(i));
        //     }
        // }

        filename = evt.dataTransfer.files[0].name;
        ext = "." + filename.substr(filename.lastIndexOf('.') + 1);
        
        if (this.state.allowedExtensions.includes(ext)){
            newFilesList.push(evt.dataTransfer.files.item(0));
        }
        
        this.props.onFileDropedToList(newFilesList)

        this.setState({ highlight: false });
    }

    getProgressWidth(index) {
        let width = 0;

        if (this.props.filesListProgress[index] !== 'undefined'){
            width = this.props.filesListProgress[index] + '%';
        }

        return width;
    }

    clearDropzone = () => {
        this.props.onClearFilesList();
    }

    render () {
        let inputClasses = [classes.Dropzone];
        let dropzoneOuterClasses = [classes.Dropzone_outer];

        if (this.state.highlight === true){
            inputClasses.push(classes.Highlight);
        }

        if (this.props.filesList.length > 0){
            dropzoneOuterClasses.push(classes.FilesAdded);
        }

        let fileList = (
            <div className={classes.FileList}>
                {this.props.filesList.map((fileItem, index) => (
                    <div className={classes.FileItem} key={index}>
                        <div className={classes.FileItemNameRow}>
                            <span>{fileItem.name}</span>
                            <div className={this.props.filesListProgress[index] === 100 ? [classes.FileLoadedWrapper, classes.FileLoadedCheck].join(' ') : classes.FileLoadedWrapper}>
                                <DropzoneButton clicked={() => this.props.onRemoveFileFromFileList(index)} value={index} btnShape="Circle" btnType="Red"><span>-</span></DropzoneButton>
                                <div className={classes.FileLoadedIcon}>
                                    &#10004;
                                </div>
                            </div>
                        </div>
                        
                        <div className={classes.FileProgressBarBackground}>
                            <div className={classes.FileProgressBar} style={{width:this.getProgressWidth(index)}}></div>
                        </div>
                    </div>
                ))}
            </div>
        )
        
        return (
            <div className={dropzoneOuterClasses.join(' ')}>
                <div className={classes.Dropzone_inner}>
                    <div
                        className={inputClasses.join(' ')}
                        id="Dropzone"
                        onDragOver={(event) => this.onDragOver(event)}
                        onDragLeave={() => this.onDragLeave()}
                        onDrop={(event) => this.onDrop(event)}
                        onClick={this.openFileDialog}
                    >
                        <input
                            ref={ref => this.fileInputRef = ref}
                            className={classes.FileInput}
                            type="file"
                            // multiple
                            onChange={(event) => this.props.onFileAddedToList(event)}
                            accept={this.state.allowedExtensions.toString()}
                        />
                        <svg className={classes.UploadIcon} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                            <path d='M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z'/>
                        </svg>
                        <span className={classes.UploadLabel}>Upload Files</span>
                    </div>
                    {fileList}
                </div>

                <div className={classes.FlexRow}>
                    <div className={classes.ClearButtonWrapper}>
                        <DropzoneButton className={classes.UploadFilesButton} btnShape="Rectangle" btnType="Red" clicked={this.clearDropzone}>Clear</DropzoneButton>
                    </div>
                    <DropzoneButton className={classes.UploadFilesButton} btnShape="Rectangle" btnType="Green" clicked={this.props.onUploadFiles}>Upload</DropzoneButton>
                </div>
            </div>
          )
    }
}

const mapStateToProps = state => {
    return {
        filesList: state.dropzone.filesList,
        filesListProgress: state.dropzone.filesListProgress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveFileFromFileList: (index) => dispatch(actions.removeFileFromFileList(index)),
        onFileAddedToList: (event) => dispatch(actions.fileAddedToList(event)),
        onFileDropedToList: (event) => dispatch(actions.fileDropedToList(event)),
        onUploadFiles: () => dispatch(actions.uploadFiles()),
        onClearFilesList: () => dispatch(actions.clearFilesList())
    }
}

// export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef : true })(withErrorHandler( Dropzone, axios ));
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef : true })(Dropzone);
