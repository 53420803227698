import React from 'react';
import L from 'leaflet';

import classes from './DownloadItem.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MapAsImage from '../MapAsImage/MapAsImage';

const CONFIG = require('../../reactConfig.json');

const downloadItem = (props) => {  
    let aoi = {};
    let coords = []
    let downloadLink = "";

    // Token
    let token = null;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user){
        token = user.token;
    }

    if(props.mapLayer.bounds.length > 0){
        coords.push(
            {lat:props.mapLayer.bounds[0][0], lng:props.mapLayer.bounds[0][1]},
            {lat:props.mapLayer.bounds[1][0], lng:props.mapLayer.bounds[0][1]},
            {lat:props.mapLayer.bounds[1][0], lng:props.mapLayer.bounds[1][1]},
            {lat:props.mapLayer.bounds[0][0], lng:props.mapLayer.bounds[1][1]},
        )
        aoi = L.polygon(coords).toGeoJSON();
    }
    aoi.bounds = props.mapLayer.bounds;

    if (props.mapLayer.downloadLink){
        downloadLink = props.mapLayer.downloadLink.split("&file=")[1];
    }

    return(
        <div className={classes.DownloadItem}>
            <Tabs
                className={classes.Tabs}
                selectedTabClassName={classes.SelectedTab}
            >
                <TabList className={classes.TabList}>
                    <Tab>
                        <span>Item</span>
                    </Tab>
                    <Tab>
                        <span>Metadata</span>
                    </Tab>
                </TabList>

                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <div className={classes.TitleRow}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z"></path></svg>
                            <span>{props.mapLayer.label}</span>
                        </div>
                        <div className={classes.Description}>
                            <p>
                                {props.mapLayer.description}
                            </p>
                        </div>
                        {/* <div className={classes.Format}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                            <span>{props.mapLayer.data_format}</span>
                        </div>
                        <div className={classes.LastUpdate}>
                            <span>Last update: {props.mapLayer.last_update}</span>
                        </div> */}
                        <a className={classes.Format} href={`${CONFIG.phpScripts}proxy.php?&request=DirectDownload&downloadLink=${downloadLink}&filename=${props.mapLayer.geoserver_layer}&token=${token}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                            <span>{props.mapLayer.data_format}</span>
                        </a>
                        {/* <a className={classes.Format} href={props.mapLayer.downloadLink}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                            <span>{props.mapLayer.data_format}</span>
                        </a> */}
                        <div className={classes.LastUpdate}>
                            <span>Date: {props.mapLayer.date}</span>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <h3>{props.mapLayer.name}</h3>
                    
                        <div className={classes.MetadataDescription}>
                            <p>
                                {props.mapLayer.description}
                            </p>
                        </div>

                        <div className={classes.BoundariesMap}>
                            <MapAsImage aoi={aoi} showBackground={true} />
                        </div>

                        <div className={classes.Metadata}>
                            <h4>Metadata</h4>
                            <ul className={classes.MetadataTable}>
                                <li>
                                    <div>Creator</div>
                                    <div>{props.mapLayer.creator}</div>
                                </li>
                                <li>
                                    <div>Date</div>
                                    <div>{props.mapLayer.last_update}</div>
                                </li>
                                <li>
                                    <div>Language</div>
                                    <div>{props.mapLayer.language}</div>
                                </li>
                                <li>
                                    <div>Coordinate system</div>
                                    <div>{props.mapLayer.coordinate_system}</div>
                                </li>
                                <li>
                                    <div>Format</div>
                                    <div>{props.mapLayer.data_format}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    

                </TabPanel>
            </Tabs>
        </div>
    )
};

export default downloadItem;