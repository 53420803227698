import axios from 'axios';

const CONFIG = require('./reactConfig.json');

const instance = axios.create({
    baseURL: CONFIG.phpScripts,
    crossDomain: true,
    withCredentials: false,
    //credentials: 'same-origin',
    //mode: 'no-cors',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default instance;