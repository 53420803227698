import React from "react";

import classes from "./Statistics.module.css";
import Button from '../UI/Buttons/Button';
import Select from '../UI/Select/Select';
import Spinner from '../Spinner/Spinner';

const statistics = (props) => {

    let selectOptions = [];
    props.mapLayersRaster.forEach((mapLayerRaster,index) => {
        selectOptions.push({value:mapLayerRaster.raster_filename, label:mapLayerRaster.label});
    });

    const selectedMapLayerRaster = props.mapLayersRaster.filter(mapLayerRaster => mapLayerRaster.raster_filename === props.statsLayerName)[0];
    let units = "";
    if (selectedMapLayerRaster.units) units = selectedMapLayerRaster.units;

    return(
        <div>
            <div className={classes.LayerSelection}>
                <span>Layer</span>
                <Select 
                    options={selectOptions}
                    defaultValue={props.statsLayerName}
                    changed={props.updateStatsLayer}
                />
            </div>

            <div className={classes.GetStatisticsButtonWrapper}>
                <Button clicked={props.handleGetStatistics}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z"></path></svg>
                    <span>Get statistics</span>
                </Button>
            </div>
            
            
            <div className={classes.StatisticsResults}>
                <div className={props.statsLoading ? [classes.SpinnerWrapper, classes.Show].join(' ') : classes.SpinnerWrapper}>
                    <Spinner themeDark />
                </div>
                <div className={classes.StatsRow}><h4>Count</h4><span>{props.statistics.count !== undefined ? props.statistics.count + " pixels" : null}</span></div>
                <div className={classes.StatsRow}><h4>Minimum</h4><span>{props.statistics.min !== undefined ? Math.round(props.statistics.min * 1000) / 1000 + " " + units : null}</span></div>
                <div className={classes.StatsRow}><h4>Maximum</h4><span>{props.statistics.max !== undefined ? Math.round(props.statistics.max * 1000) / 1000 + " " + units: null}</span></div>
                <div className={classes.StatsRow}><h4>Mean</h4><span>{props.statistics.mean !== undefined ? Math.round(props.statistics.mean * 1000) / 1000 + " " + units : null}</span></div>
                <div className={classes.StatsRow}><h4>St. Deviation</h4><span>{props.statistics.stddev !== undefined ? Math.round(props.statistics.stddev * 1000) / 1000 + " " + units : null}</span></div>
                {/* <div className={classes.StatsRow}><h4>Sum</h4><span>{props.statistics.sum !== undefined ? Math.round(props.statistics.sum * 1000) / 1000 + " " + units : null}</span></div> */}
            </div>
        </div>
    )
};

export default statistics;