import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    showModal:false,
    show:"",
    loggedIn:false,
    loggedInUser:{
        id:null,
        username:null,
        role:null
    },
    confirmationMessage:null,
    registering:false
}

const showLoginRegister = (state,action) => {
    return updateObject(state, {showModal:true, show:action.showType});
}

const hideLoginRegister = (state) => {
    return updateObject(state, {showModal:false, show:"", confirmationMessage:null});
}

const loggedIn = (state,action) => {
    return updateObject(state, {loggedIn:true, loggedInUser:action.user, showModal:false, show:"", confirmationMessage:null});
}

const loggedOut = (state) => {
    return initialState;
}

const getUserFromLocalStorage = (state) => {

    let user = {};
    
    if (localStorage.getItem('user') !== 'undefined') {     
        user = JSON.parse(localStorage.getItem('user'));
    }

    let loggedIn = false;

    if (user !== null && user.hasOwnProperty('id')) loggedIn = true;

    return updateObject(state, {loggedIn:loggedIn, loggedInUser:user});
}

const showConfirmation = (state,action) => {
    return updateObject(state, {
        showModal: true,
        show:"confirmation",
        confirmationMessage:action.confirmationMessage
    });
}

const registerUserStarted = (state) => {
    return updateObject(state, {registering: true});
}

const registerUserDone = (state) => {
    return updateObject(state, {registering: false});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SHOW_LOGIN_REGISTER: return showLoginRegister(state,action);
        case actionTypes.HIDE_LOGIN_REGISTER: return hideLoginRegister(state);
        case actionTypes.LOGGED_IN: return loggedIn(state,action);
        case actionTypes.LOGGED_OUT: return loggedOut(state);
        case actionTypes.GET_USER_FROM_LOCAL_STORAGE: return getUserFromLocalStorage(state);
        case actionTypes.SHOW_CONFIRMATION: return showConfirmation(state,action);
        case actionTypes.REGISTER_USER_STARTED: return registerUserStarted(state);
        case actionTypes.REGISTER_USER_DONE: return registerUserDone(state);
        default: return state;
    }
};

export default reducer;