import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Basket.module.css';
import MapAsImage from '../../components/MapAsImage/MapAsImage';
import CloseButton from '../../components/UI/Buttons/CloseButton';
import Button from '../../components/UI/Buttons/Button';
import * as actions from '../../store/actions/index';

class Basket extends Component {
    
    state={
        notLoggedIn:false
    }

    prepareSendRequest = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user){
            this.props.onSendRequest(this.props.basketItems)
            this.setState({notLoggedIn:false})

        } else {
            this.setState({notLoggedIn:true})
        }
    }

    render () {
        let basketItems = [];
        let sumValue = 0;

        if (this.props.basketItemsNum > 0){
            this.props.basketItems.forEach((basketItem,index) => {
                basketItems.push(<div key={index} className={classes.FlexBox}>
                                    <div className={[classes.Col, classes.Col1].join(' ')}>{index + 1}.</div>
                                    <div className={[classes.Col, classes.Col3].join(' ')}>{basketItem.season.label + ' ' + basketItem.year.label}</div>
                                    <div className={[classes.Col, classes.Col4].join(' ')}>
                                        <MapAsImage aoi={basketItem.aoi} showBackground={false} />
                                    </div>
                                    <div className={[classes.Col, classes.Col5].join(' ')}>
                                        {basketItem.value.toFixed(2)}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.706 413.765c-1.314-6.63-7.835-10.872-14.424-9.369-10.692 2.439-27.422 5.413-45.426 5.413-56.763 0-101.929-34.79-121.461-85.449h113.689a12 12 0 0 0 11.708-9.369l6.373-28.36c1.686-7.502-4.019-14.631-11.708-14.631H115.22c-1.21-14.328-1.414-28.287.137-42.245H261.95a12 12 0 0 0 11.723-9.434l6.512-29.755c1.638-7.484-4.061-14.566-11.723-14.566H130.184c20.633-44.991 62.69-75.03 117.619-75.03 14.486 0 28.564 2.25 37.851 4.145 6.216 1.268 12.347-2.498 14.002-8.623l11.991-44.368c1.822-6.741-2.465-13.616-9.326-14.917C290.217 34.912 270.71 32 249.635 32 152.451 32 74.03 92.252 45.075 176H12c-6.627 0-12 5.373-12 12v29.755c0 6.627 5.373 12 12 12h21.569c-1.009 13.607-1.181 29.287-.181 42.245H12c-6.627 0-12 5.373-12 12v28.36c0 6.627 5.373 12 12 12h30.114C67.139 414.692 145.264 480 249.635 480c26.301 0 48.562-4.544 61.101-7.788 6.167-1.595 10.027-7.708 8.788-13.957l-8.818-44.49z"></path></svg>
                                    </div>
                                    <div className={[classes.Col, classes.Col6].join(' ')}>
                                        <CloseButton red clicked={() => this.props.onRemoveBasketItem(index)} />
                                    </div>
                                </div>)

                sumValue = sumValue + basketItem.value;
            });
        }


        return(
            <div className={classes.BasketWrapper}>
                <div className={this.state.notLoggedIn ? [classes.Message, classes.Error].join(" ") : classes.Message }>
                    <span>Please log in first</span>
                </div>
                <div className={classes.BasketItemsWrapper}>
                    <div className={classes.FlexBox}>
                        <div className={[classes.Col, classes.Col1, classes.TableHeader].join(' ')}>s/n</div>
                        <div className={[classes.Col, classes.Col3, classes.TableHeader].join(' ')}>Date</div>
                        <div className={[classes.Col, classes.Col4, classes.TableHeader].join(' ')}>Area</div>
                        <div className={[classes.Col, classes.Col5, classes.TableHeader].join(' ')}>Value</div>
                        <div className={[classes.Col, classes.Col6, classes.TableHeader].join(' ')}></div>
                    </div>
                    {basketItems}
                </div>
                <div className={classes.SumValueWrapper}>
                    <div className={classes.SumValueLabel}>Total</div>
                    <div className={classes.SumValue}>
                        {sumValue.toFixed(2)}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.706 413.765c-1.314-6.63-7.835-10.872-14.424-9.369-10.692 2.439-27.422 5.413-45.426 5.413-56.763 0-101.929-34.79-121.461-85.449h113.689a12 12 0 0 0 11.708-9.369l6.373-28.36c1.686-7.502-4.019-14.631-11.708-14.631H115.22c-1.21-14.328-1.414-28.287.137-42.245H261.95a12 12 0 0 0 11.723-9.434l6.512-29.755c1.638-7.484-4.061-14.566-11.723-14.566H130.184c20.633-44.991 62.69-75.03 117.619-75.03 14.486 0 28.564 2.25 37.851 4.145 6.216 1.268 12.347-2.498 14.002-8.623l11.991-44.368c1.822-6.741-2.465-13.616-9.326-14.917C290.217 34.912 270.71 32 249.635 32 152.451 32 74.03 92.252 45.075 176H12c-6.627 0-12 5.373-12 12v29.755c0 6.627 5.373 12 12 12h21.569c-1.009 13.607-1.181 29.287-.181 42.245H12c-6.627 0-12 5.373-12 12v28.36c0 6.627 5.373 12 12 12h30.114C67.139 414.692 145.264 480 249.635 480c26.301 0 48.562-4.544 61.101-7.788 6.167-1.595 10.027-7.708 8.788-13.957l-8.818-44.49z"></path></svg>
                    </div>
                </div>
                <div className={classes.PurchaseButtonWrapper}>
                    <Button disabled={this.props.basketItems.length === 0} clicked={() => this.prepareSendRequest()}>
                        <span>Proceed to payment</span>
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        basketItemsNum: state.basket.basketItemsNum,
        basketItems: state.basket.basketItems,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveBasketItem: (index) => dispatch(actions.removeBasketItem(index)),
        onSendRequest: (basketItems) => dispatch(actions.sendRequest(basketItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);