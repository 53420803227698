import * as actionTypes from './actionTypes';
import axios from '../../axios-api';


export const getProductsOrders = () => {
    return dispatch => {
        axios.get( 'getProductsOrders.php')
            .then( response => {
                dispatch(getProductsOrdersSuccess(response.data));
            })
            .catch( error => {
                // dispatch(getProductsOrdersFailed());
            });
    };
};

export const getProductsOrdersSuccess = (orders) => {
    return {
        type: actionTypes.GET_PRODUCTS_ORDERS_SUCCESS,
        orders: orders
    };
};


export const updateOrderState = (index,value,actionType) => {
    return dispatch => {
        axios.post( 'updateOrderState.php', {index:index,value:value})
            .then( response => {
                dispatch(updateAdminTable(index,value,actionType));
                //dispatch(updateGeoserverLayerAuth(value));
            })
            .catch( error => {
                // dispatch(updateAdminTableFailed());
            });
    };
};

export const updateAdminTable = (index,value,actionType) => {
    return {
        type: actionTypes.UPDATE_ADMIN_TABLE,
        index:index,
        value:value,
        actionType:actionType
    };
};

export const updateGeoserverLayerAuth = (value) => {
    return dispatch => {
        axios.post( 'updateGeoserverLayerAuth.php', {value:value})
            .then( response => {
                console.log(response.data)
            })
            .catch( error => {
                // dispatch(updateAdminTableFailed());
            });
    };
};