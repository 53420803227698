import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    message: "",
    type: ""
}

const alertSuccess = (state, action) => {
    return updateObject(state, {message:action.message, type: "Success"});
}

const alertError = (state, action) => {
    return updateObject(state, {message:action.message, type: "Error"});
}

const alertClear = (state) => {
    return updateObject(state, {message:"", type:""});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ALERT_SUCCESS: return alertSuccess(state, action);
        case actionTypes.ALERT_ERROR: return alertError(state, action);
        case actionTypes.ALERT_CLEAR: return alertClear(state);
        default: return state;
    }
};

export default reducer;