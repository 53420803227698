import React from 'react';

import classes from './SwitchButton.module.css';

const switchButton = (props) => {
    
    let attachedClasses = [classes.SwitchButton];

    if (props.active){
        attachedClasses.push(classes.Active)
    }

    return(
        <button
            className={attachedClasses.join(' ')}
            onClick={props.clicked}
        >
        </button>
    )
};

export default switchButton;