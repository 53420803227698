import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
        minWidth: "150px",
        marginTop: "25px"
    }
});

const RangeSlider = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState([props.min, props.max]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.changed(newValue);
    };

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#82AE2C'
            },
        },
    });

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Slider
                    min={props.min}
                    max={props.max}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                />
            </ThemeProvider>
        </div>
    );
}

export default RangeSlider