import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import classes from './Products.module.css';
import PanelDataItem from '../../components/PanelDataItem/PanelDataItem';
import Button from '../../components/UI/Buttons/Button';
import Select from 'react-select';
import MapAsImage from '../../components/MapAsImage/MapAsImage';
import Basket from '../Basket/Basket';
import BasketContact from '../../components/BasketContact/BasketContact';
import LayerItem from '../../components/LayerItem/LayerItem';
import * as actions from '../../store/actions/index';

class Products extends Component {
    
    state={
        tabIndex:0,
        selectedYear:null,
        selectedSeason:null,
        years:null,
        months:[
            {value:1, label:'January'},
            {value:2, label:'February'},
            {value:3, label:'March'},
            {value:4, label:'April'},
            {value:5, label:'May'},
            {value:6, label:'June'},
            {value:7, label:'July'},
            {value:8, label:'August'},
            {value:9, label:'September'},
            {value:10, label:'October'},
            {value:11, label:'November'},
            {value:12, label:'December'}
        ],
        seasons:[
            {value:'summer', label:'Summer'},
            {value:'winter', label:'Winter'}
        ],
        purchasable: false,
    }

    componentDidMount() {
        this.getYears();
    }

    componentWillUnmount() {
        this.props.onRemovePanelFullWidth();
    }

    selectedTabHandle(tabIndex) {
        // if (tabIndex === 1 || tabIndex === 2) this.props.onAddPanelFullWidth();
        // else this.props.onRemovePanelFullWidth();

        this.setState({ tabIndex })
    }

    handleYearChange = selectedYear => {
        this.setState({selectedYear}, function () {
            this.checkValue();
        });
    }
    handleSeasonChange = selectedSeason => {
        this.setState({selectedSeason}, function () {
            this.checkValue();
        });
    }


    // Get the available years, starting at 2019
    getYears = () => {
        const startYear = 2019;
        const endYear = new Date().getFullYear();
        let years = [];

        for (let year=startYear; year<=endYear; year++){
            years.push({value:year, label:year});
        }

        this.setState({years:years})
    }

    
    checkValue = () => {
        if (this.props.aoi && this.state.selectedYear && this.state.selectedSeason){
            // this.calculateValue();
            this.props.onCalculateProductValue(this.props.uploadedFileId);
            this.setState({purchasable:true});
        } else {
            this.props.onUpdateProductValue(null);
            this.setState({purchasable:false});
        }
    }

    prepareAddToBasket = (aoi,year,season,value) => {
        const basketItem = {
            aoi:aoi,
            uploadedFileId:this.props.uploadedFileId,
            year:year,
            season:season,
            value:value
        }
        this.props.onAddToBasket(basketItem);
    }

    clearAOI() {
        this.props.onClearAOI();
        this.props.onClearSentinelImages();
        this.props.onUpdateProductValue(null);
        this.setState({purchasable:false});
    }

    render () {
        let userProducts_sentinel = [];
        let userProducts_pasture = [];
        let userProducts_plant = [];
        let userProducts_condition = [];
        let userProducts_grazing = [];

        this.props.mapLayers.map((mapLayer,index) => {
            let layerItem = <LayerItem
                                key={index}
                                collection="mapLayers"
                                mapLayer={mapLayer}
                                index={index}
                                showSymbol={true}
                                showZoom
                                showVisibility
                                showOpacity
                            />

            if (mapLayer.data_subgroup_id === 3){
                userProducts_sentinel.push(layerItem)
            } else if (mapLayer.data_subgroup_id === 4){
                userProducts_pasture.push(layerItem)
            } else if (mapLayer.data_subgroup_id === 5){
                userProducts_plant.push(layerItem)
            } else if (mapLayer.data_subgroup_id === 6){
                userProducts_condition.push(layerItem)
            } else if (mapLayer.data_subgroup_id === 7){
                userProducts_grazing.push(layerItem)
            }
        })

        return(
            <Tabs
                className={classes.Tabs}
                selectedTabClassName={classes.SelectedTab}
                selectedIndex={this.state.tabIndex} 
                onSelect={tabIndex => this.selectedTabHandle(tabIndex)}>
            
                <TabList className={classes.TabList}>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"></path></svg>
                        </div>
                        <span>Products</span>
                    </Tab>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.706 413.765c-1.314-6.63-7.835-10.872-14.424-9.369-10.692 2.439-27.422 5.413-45.426 5.413-56.763 0-101.929-34.79-121.461-85.449h113.689a12 12 0 0 0 11.708-9.369l6.373-28.36c1.686-7.502-4.019-14.631-11.708-14.631H115.22c-1.21-14.328-1.414-28.287.137-42.245H261.95a12 12 0 0 0 11.723-9.434l6.512-29.755c1.638-7.484-4.061-14.566-11.723-14.566H130.184c20.633-44.991 62.69-75.03 117.619-75.03 14.486 0 28.564 2.25 37.851 4.145 6.216 1.268 12.347-2.498 14.002-8.623l11.991-44.368c1.822-6.741-2.465-13.616-9.326-14.917C290.217 34.912 270.71 32 249.635 32 152.451 32 74.03 92.252 45.075 176H12c-6.627 0-12 5.373-12 12v29.755c0 6.627 5.373 12 12 12h21.569c-1.009 13.607-1.181 29.287-.181 42.245H12c-6.627 0-12 5.373-12 12v28.36c0 6.627 5.373 12 12 12h30.114C67.139 414.692 145.264 480 249.635 480c26.301 0 48.562-4.544 61.101-7.788 6.167-1.595 10.027-7.708 8.788-13.957l-8.818-44.49z"></path></svg>
                        </div>
                        <span>Purchase</span>
                    </Tab>
                    <Tab>
                        <div className={classes.IconWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path></svg>
                        </div>
                        {this.props.basketItemsNum > 0 ? 
                            <div className={classes.BasketNumWrapper}><span>{this.props.basketItemsNum}</span></div>
                            : 
                            null
                        }
                        
                        <span>Basket</span>
                    </Tab>
                </TabList>

                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="Products">

                            <Button 
                                style={{width:"100%",background:"#82AE2C",color:"#EAFEC4"}}
                                clicked={() => this.selectedTabHandle(1)}>
                                <span>Get new products</span>
                            </Button>

                            <div className={classes.Margin}></div>

                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Sentinel-2 derived spectral indices and band ratios</h4>

                                {userProducts_sentinel.length > 0 ?
                                    userProducts_sentinel
                                    :
                                    <span>You have no available products</span>
                                }
                            </div>
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Pasture productivity</h4>

                                {userProducts_pasture.length > 0 ?
                                    userProducts_pasture
                                    :
                                    <span>You have no available products</span>
                                }
                            </div>
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Plant cover maps</h4>

                                {userProducts_plant.length > 0 ?
                                    userProducts_plant
                                    :
                                    <span>You have no available products</span>
                                }
                            </div>
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Pasture condition</h4>

                                {userProducts_condition.length > 0 ?
                                    userProducts_condition
                                    :
                                    <span>You have no available products</span>
                                }
                            </div>
                            <div className={classes.SubCategory}>
                                <h4 className={classes.SubTitle}>Grazing capacity</h4>

                                {userProducts_grazing.length > 0 ?
                                    userProducts_grazing
                                    :
                                    <span>You have no available products</span>
                                }
                            </div>
                            
                        </PanelDataItem>
                    </div>
                </TabPanel>
                
                <TabPanel 
                    className={classes.TabPanel}
                    selectedClassName={classes.SelectedTabPanel}
                >
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="Purchase products">

                            <div className={classes.PurchaseWrapper}>
                                <div className={this.props.aoi ? [classes.Step,classes.Complete,classes.FlexRowTop].join(' ') : [classes.Step,classes.FlexRow].join(' ')}>
                                    <div className={classes.FlexRow}>
                                        <div className={classes.StepNumberWrapper}>
                                            <div className={classes.StepNumber}>
                                                <span>1</span>
                                            </div>
                                        </div>
                                        <span className={classes.StepDescription}>Define an area of interest</span>
                                    </div>
                                    {this.props.aoi ?
                                        <div className={classes.AOISelectedWrapper}>
                                            <Button style={{width:"160px", margin:"0"}} clicked={() => this.clearAOI()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497.941 273.941c18.745-18.745 18.745-49.137 0-67.882l-160-160c-18.745-18.745-49.136-18.746-67.883 0l-256 256c-18.745 18.745-18.745 49.137 0 67.882l96 96A48.004 48.004 0 0 0 144 480h356c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H355.883l142.058-142.059zm-302.627-62.627l137.373 137.373L265.373 416H150.628l-80-80 124.686-124.686z"></path></svg>
                                                <span>Clear</span>
                                            </Button>
                                            <div className={classes.AOISelected}>
                                                <MapAsImage aoi={this.props.aoi} showBackground={true} />
                                            </div>
                                        </div>
                                        :
                                        <Button clicked={() => this.props.onClickDataButton(0)}>
                                            <span>Go to AOI</span>
                                        </Button>
                                    }
                                </div>

                                <div className={this.state.selectedYear && this.state.selectedSeason ? [classes.Step,classes.Complete,classes.FlexRow].join(' ') : [classes.Step,classes.FlexRow].join(' ')}>
                                    <div className={classes.FlexRow}>
                                        <div className={classes.StepNumberWrapper}>
                                            <div className={classes.StepNumber}>
                                                <span>2</span>
                                            </div>
                                        </div>
                                        <span className={classes.StepDescription}>Select a date</span>
                                    </div>
                                    
                                    <div className={classes.DateSelection}>
                                        <Select 
                                            className={classes.SelectComp}
                                            value={this.state.selectedYear}
                                            options={this.state.years}
                                            placeholder="Select year..." 
                                            onChange={this.handleYearChange} />

                                        {/* {this.state.purchaseProduct.value === "sentinel" ? */}
                                            <Select 
                                                className={classes.SelectComp}
                                                value={this.state.selectedSeason}
                                                options={this.state.months}
                                                placeholder="Select month..." 
                                                onChange={this.handleSeasonChange} />
                                        {/* : 
                                            <Select 
                                                className={classes.SelectComp}
                                                value={this.state.selectedSeason}
                                                options={this.state.seasons}
                                                placeholder="Select season..." 
                                                onChange={this.handleSeasonChange} />
                                        } */}
                                    </div>
                                </div>

                                <div className={this.props.productValue ? [classes.Step,classes.Complete,classes.FlexRow].join(' ') : [classes.Step,classes.FlexRow].join(' ')}>
                                    <div className={classes.FlexRow}>
                                        <div className={classes.StepNumberWrapper}>
                                            <div className={classes.StepNumber}>
                                                <span>3</span>
                                            </div>
                                        </div>
                                        <span className={classes.StepDescription}>Value</span>
                                    </div>

                                    <div className={this.props.productValue ? [classes.ValueBox,classes.ValueCalculated].join(' ') : classes.ValueBox}>
                                        {this.props.productValue ? <span>{this.props.productValue.toFixed(2)}</span> : null}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.706 413.765c-1.314-6.63-7.835-10.872-14.424-9.369-10.692 2.439-27.422 5.413-45.426 5.413-56.763 0-101.929-34.79-121.461-85.449h113.689a12 12 0 0 0 11.708-9.369l6.373-28.36c1.686-7.502-4.019-14.631-11.708-14.631H115.22c-1.21-14.328-1.414-28.287.137-42.245H261.95a12 12 0 0 0 11.723-9.434l6.512-29.755c1.638-7.484-4.061-14.566-11.723-14.566H130.184c20.633-44.991 62.69-75.03 117.619-75.03 14.486 0 28.564 2.25 37.851 4.145 6.216 1.268 12.347-2.498 14.002-8.623l11.991-44.368c1.822-6.741-2.465-13.616-9.326-14.917C290.217 34.912 270.71 32 249.635 32 152.451 32 74.03 92.252 45.075 176H12c-6.627 0-12 5.373-12 12v29.755c0 6.627 5.373 12 12 12h21.569c-1.009 13.607-1.181 29.287-.181 42.245H12c-6.627 0-12 5.373-12 12v28.36c0 6.627 5.373 12 12 12h30.114C67.139 414.692 145.264 480 249.635 480c26.301 0 48.562-4.544 61.101-7.788 6.167-1.595 10.027-7.708 8.788-13.957l-8.818-44.49z"></path></svg>
                                    </div>
                                </div>

                                <div className={this.props.productValue ? [classes.Step,classes.Complete,classes.FlexRow].join(' ') : [classes.Step,classes.FlexRow].join(' ')}>
                                    <div className={classes.FlexRow}>
                                        <div className={classes.StepNumberWrapper}>
                                            <div className={classes.StepNumber}>
                                                <span>4</span>
                                            </div>
                                        </div>
                                        <span className={classes.StepDescription}>Add to basket</span>
                                    </div>

                                    <Button disabled={!this.state.purchasable} clicked={() => this.prepareAddToBasket(this.props.aoi,this.state.selectedYear,this.state.selectedSeason,this.props.productValue)}>
                                        <span>Add to basket</span>
                                    </Button>
                                    
                                </div>
                            </div>
                        </PanelDataItem>
                    </div>
                </TabPanel>
                <TabPanel className={classes.TabPanel}>
                    <div className={classes.PanelContentWrapper}>
                        <PanelDataItem
                            title="Basket">

                            {this.props.requestID ? 
                                <BasketContact requestID={this.props.requestID} />
                            :
                                <Basket />
                            }
                        </PanelDataItem>
                    </div>
                </TabPanel>
            </Tabs>
        )
    }
}

const mapStateToProps = state => {
    return {
        mapLayers: state.layerItems.mapLayers,
        aoi: state.content.aoi,
        uploadedFileId: state.content.uploadedFileId,
        basketItemsNum: state.basket.basketItemsNum,
        requestID: state.basket.requestID,
        productValue: state.content.productValue
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onClearAOI: () => dispatch(actions.clearAOI()),
        onClearSentinelImages: () => dispatch(actions.clearSentinelImages()),
        onAddPanelFullWidth: () => dispatch(actions.addPanelFullWidth()),
        onRemovePanelFullWidth: () => dispatch(actions.removePanelFullWidth()),
        onClickDataButton: (index) => dispatch(actions.clickDataButton(index)),
        onAddToBasket: (basketItem) => dispatch(actions.addToBasket(basketItem)),
        onCalculateProductValue: (aoi_id) => dispatch(actions.calculateProductValue(aoi_id)),
        onUpdateProductValue: (value) => dispatch(actions.updateProductValue(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
