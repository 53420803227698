import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import ReactResizeDetector from 'react-resize-detector';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import { Map, FeatureGroup, Circle, Polygon, Pane, Marker, Popup, WMSTileLayer, withLeaflet, GeoJSON, TileLayer } from 'react-leaflet';
// import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import PrintControlDefault from 'react-leaflet-easyprint';
import { CoordinatesControl } from 'react-leaflet-coordinates'
// import ResizePanel from "react-resize-panel";

import classes from './Content.module.css';
import Panel from '../../components/UI/Panel/Panel';
import Tools from '../Tools/Tools';
import Data from '../Data/Data';
import Downloads from '../Downloads/Downloads';
import Products from '../Products/Products';
import CloseButton from '../../components/UI/Buttons/CloseButton';
import Spinner from '../../components/Spinner/Spinner';
import Swipe from '../../components/Swipe/Swipe';
import * as actions from '../../store/actions/index';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// import WmsWithHeader from '../../components/WmsWithHeader/WmsWithHeader';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const PrintControl = withLeaflet(PrintControlDefault);

class Content extends Component {
    
    state={
        mapLayerGeojson:{},
        mapLayerPointsGeojson:{},
        mapZoom:10,
        popupCenter:null,
        popupKey:null,
        popupFullScreen:false,
        tiles:[]
    }

    componentDidMount () {
        this.props.onUpdateMapKey();

        // enable ctrl Z for undo when creating new aoi
        document.onkeydown = this.keyPress;
        
        this.props.onGetMapLayers();
        this.onZoom();
    }

    keyPress = (e) => {
        if (e.keyCode === 90 && e.ctrlKey && this.props.drawing){
            this.props.onRemoveDrawLastPoint();
        };
    }

    onMapResize = () => {
        this.mapRef.leafletElement.invalidateSize();
    }

    onMapClick(e) {
        if (this.props.drawing){
            this.props.onAddDrawPoint(e.latlng);
            const mapLayerGeojson = L.polygon(this.props.mapLayerCoords).toGeoJSON();
            const mapLayerPointsGeojson = L.circle(this.props.mapLayerCoords[0]).toGeoJSON();

            this.setState({mapLayerGeojson:mapLayerGeojson, mapLayerPointsGeojson:mapLayerPointsGeojson})
        } else{
            this.prepareGetFeatureInfo(e.latlng)
        }
    }

    prepareGetFeatureInfo(latlng){
        var d = new Date();
        this.setState({popupCenter:latlng, popupKey: 'popup_' + d.getMilliseconds()}); // Get time in miliseconds. The popup key will be based on this and prevent flickering while the daa is loaded

        const LAT = latlng.lat;
        const LNG = latlng.lng;
        const BBOX = this.mapRef.leafletElement.getBounds().toBBoxString();
        const WIDTH = this.mapRef.leafletElement.getSize().x;
        const HEIGHT = this.mapRef.leafletElement.getSize().y;

        const bds = this.mapRef.leafletElement.getBounds();
        const w = bds.getNorthEast().lng - bds.getSouthWest().lng;
        const h = bds.getNorthEast().lat - bds.getSouthWest().lat;
        const X = (((LNG - bds.getSouthWest().lng) / w) * WIDTH).toFixed(0);
        const Y = (((bds.getNorthEast().lat - LAT) / h) * HEIGHT).toFixed(0);

        const URL = '&bbox=' + BBOX + '&width=' + WIDTH + '&height=' + HEIGHT + '&info_format=application/json&x=' + X + '&y=' + Y;
        let layers = [];

        const mapInfoLayers = this.getInfoLayers(this.props.mapLayers);
        const queryMapInfoLayers = this.getInfoLayers(this.props.queryMapLayers);

        layers = [...mapInfoLayers, ...queryMapInfoLayers];

        if (layers.length > 0){
            this.props.onGetFeatureInfo(URL, layers);
        }
    }

    getInfoLayers(collection){
        let layers = [];
        
        collection.forEach(mapLayer => {
            if (mapLayer.connection === "wms" && mapLayer.visible === true){
                layers.push({layerName:mapLayer.geoserver_layer, layerLabel:mapLayer.label});
            }
        });

        return layers;
    }

    onZoom = () => {
        let mapZoom = 10
        if (this.mapRef){
            mapZoom = this.mapRef.leafletElement.getZoom();
        }

        this.setState({mapZoom:mapZoom})
    }

    toggleFullScreen = () => {
        const toggle = !this.state.popupFullScreen;
        this.setState({popupFullScreen:toggle})
    }

    closePopup = () => {
        this.setState({popupFullScreen:false});
        this.props.onClosePopup();
    }

    render () {
        let panelContent = null;
        let drawFeatures = null;
        let searhResult = null;
        let mapLayers = [];
        let mapLayerItem = null;
        let popupHeader = null;
        let popupContent = null;
        let mapClasses = [classes.Map];
        let sideMapClasses = [classes.SideMap];
        let fullScreenPopupClasses = [classes.PopupFullScreenWrapper];
        let queryMapLayers = [];
 
        const user = JSON.parse(localStorage.getItem('user'));
        let token = null;
        if (user){
            token = user.token;
        }

        if (this.props.mapLayers.length > 0){
            this.props.mapLayers.forEach((mapLayer,index) => {

                if (mapLayer.visible){
                    if (mapLayer.connection !== "internal"){
                        // if (mapLayer.type === "raster"){
                            mapLayerItem = <WMSTileLayer
                                                format="image/png"
                                                transparent={true}
                                                onLoading={() => this.props.onAddLayerLoading(mapLayer.uniqueId, "mapLayersLoading")}
                                                onLoad={() => this.props.onRemoveLayerLoading(mapLayer.uniqueId, "mapLayersLoading")}
                                                layers={mapLayer.geoserver_layer}
                                                opacity={mapLayer.layerOpacity}
                                                url={this.props.phpUrl + "proxy.php"}
                                                request="GetMap"
                                                token={token}
                                                viewparams={mapLayer.viewparams}
                                                style={mapLayer.style}
                                            />

                        // } else if (mapLayer.type === "polygon"){

                        // }
                    }

                    mapLayers.push(<FeatureGroup 
                                        key={"fg_" + index}
                                        ref={ref => this["fg_" + index] = ref}
                                    >
                                        <Pane 
                                            style={{zIndex: 400 + (this.props.mapLayers.length - index)}}>

                                            {mapLayerItem}
                                        </Pane>
                                    </FeatureGroup>)
                }
            });
        }
    
        if (this.props.drawing){
            drawFeatures = <FeatureGroup ref={ref => this.drawFeatures = ref}>
                                <Pane name="polygonPane" style={{ zIndex: 499 }}>
                                    <Polygon key={`polygon-${Math.floor(Math.random() * 100000)}`} positions={this.props.mapLayerCoords} fillColor="#b6b823" fillOpacity="0.5" color="#b6b823" dashArray="10 10" />
                                </Pane>
                                
                                <Pane name="pointPane" style={{ zIndex: 500 }}>
                                    {this.props.mapLayerCoords.map((position, idx) => 
                                        <Circle key={`circle-${idx}`} center={position} fillColor="#b6b823" fillOpacity="1" color="#b6b823" radius={350-(this.state.mapZoom*20)} />
                                    )}
                                </Pane>
                            </FeatureGroup>
            
            mapClasses.push(classes.Drawing);
        }
        
        if (this.props.searchResultItem !== null){
            searhResult = <FeatureGroup ref={ref => this.searchFeature = ref}>
                                <Marker position={[this.props.searchResultItem.y,this.props.searchResultItem.x]}>
                                    <Popup>{this.props.searchResultItem.label}</Popup>
                                </Marker>
                            </FeatureGroup>
        }

        if (this.props.activeDataButton === 0){
            panelContent = <Tools printControlRef={this.printControlRef} />            
        }
        else if (this.props.activeDataButton === 1){ 
            panelContent = <Data/>
        }
        else if (this.props.activeDataButton === 2){ 
            panelContent = <Products/>
        }
        else if (this.props.activeDataButton === 3){ 
            panelContent = <Downloads/>
        }
        
  
        if (this.state.popupFullScreen){
            mapClasses.push(classes.MapPopupFullScreen);
            fullScreenPopupClasses.push(classes.Show);
        }

        if (this.state.separatorXPosition){
            mapClasses.push(classes.noActions);
            sideMapClasses.push(classes.noActions);
        }

        popupHeader = <div className={classes.ToolRow}>
                        {this.props.loadingFeatureInfo ?
                            <div className={classes.SpinnerWrapper}>
                                <Spinner themeLight />
                            </div>
                            :
                            this.props.featureInfoLayers.length > 0 ?
                                <div className={classes.ToolRowNav}>
                                    <svg 
                                        className={this.props.currentFeatureInfoLayer === 1 ? [classes.ToolRowNavSvg,classes.Disabled].join(' ') : classes.ToolRowNavSvg}
                                        onClick={() => this.props.onChangeFeaturePanel("subtract")} 
                                        xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'>
                                        <path d='M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z'></path>
                                    </svg>
                                    <span>{this.props.currentFeatureInfoLayer} / {this.props.featureInfoLayers.length}</span>
                                    <svg 
                                        className={this.props.currentFeatureInfoLayer === this.props.featureInfoLayers.length ? [classes.ToolRowNavSvg,classes.Disabled].join(' ') : classes.ToolRowNavSvg}
                                        onClick={() => this.props.onChangeFeaturePanel("add")} 
                                        xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'>
                                        <path d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'></path>
                                    </svg>
                                </div>
                                : null
                        }
                        
                        <div className={classes.ToolRowActions}>
                            <svg className={this.state.popupFullScreen === true ? classes.Hidden : null} onClick={this.toggleFullScreen} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                <path d='M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V192h416v234z'></path>
                            </svg>
                            <svg className={this.state.popupFullScreen === false ? classes.Hidden : null} onClick={this.toggleFullScreen} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M464 0H144c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v320c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-96 464H48V256h320v208zm96-96h-48V144c0-26.5-21.5-48-48-48H144V48h320v320z"></path>
                            </svg>
                            <div className={classes.CloseButtonWrapper}>
                                <CloseButton clicked={this.closePopup} />
                            </div>
                        </div>
                    </div>

        if (this.props.featureInfoLayers.length > 0){
            popupContent = <div className={classes.PopupContentWrapper}>
                                {this.props.featureInfoLayers.map((featureInfoLayer,index) => 
                                    <div
                                        key={index}
                                        className={this.props.currentFeatureInfoLayer === index + 1 ? [classes.FeaturePanel, classes.Active].join(' ') : classes.FeaturePanel}>
                                        <h4 className={classes.LayerName}>{featureInfoLayer.layerLabel}</h4>
                                        
                                        <ul className={classes.PopupProperties}>
                                            {Object.keys(featureInfoLayer.layerData.data.features[0].properties).map(function(key) {
                                                return <li key={`popup_property_-${Math.floor(Math.random() * 100000)}`} className={classes.PropertyRow}>
                                                            <span>{key}:</span>
                                                            <span>{featureInfoLayer.layerData.data.features[0].properties[key]}</span>
                                                        </li>
                                                    
                                            })}
                                         </ul>
                                    </div>
                                )}
                            </div>
        } else {
            popupContent = <div className={classes.PopupContentWrapper}>
                                <span>No information available</span>
                            </div>
        }

        if (this.props.queryMapLayers){
            this.props.queryMapLayers.forEach((queryMapLayer,index) => {
                if (queryMapLayer.visible){
                    let queryMapLayerItem = 
                                            <WMSTileLayer
                                                format="image/png"
                                                transparent={true}
                                                onLoading={() => this.props.onAddLayerLoading(queryMapLayer.uniqueId, "queryMapLayersLoading")}
                                                onLoad={() => this.props.onRemoveLayerLoading(queryMapLayer.uniqueId, "queryMapLayersLoading")}
                                                layers={queryMapLayer.geoserver_layer}
                                                opacity={queryMapLayer.layerOpacity}
                                                url={this.props.phpUrl + "proxy.php"}
                                                request="GetMap"
                                                CQL_FILTER={queryMapLayer.filter}
                                                style=""
                                                viewparams=""
                                            />
                                            
                    queryMapLayers.push(
                        <FeatureGroup 
                            key={"queryFg_" + index}
                            ref={ref => this["queryFg_" + index] = ref}
                        >
                            <Pane 
                                style={{zIndex: 450 + (this.props.queryMapLayers.length - index)}}>

                                {queryMapLayerItem}
                            </Pane>
                        </FeatureGroup>
                    )
                }
            });
        }

        const map = <ReactResizeDetector handleWidth handleHeight onResize={this.onMapResize}>
                        <Map 
                            key={this.props.mapKey}
                            ref={ref => this.mapRef = ref}
                            className={mapClasses.join(' ')} 
                            onClick={(e) => this.onMapClick(e)}
                            onZoom={this.onZoom}
                            zoomControl={false}
                            maxZoom={17}
                            bounds={this.props.mapBounds}>

                            <TileLayer 
                                attribution={this.props.basemapLayers[this.props.selectedBasemap].attribution}
                                url={this.props.basemapLayers[this.props.selectedBasemap].url}
                            />

                            {this.props.showPopup === true ?
                                <Popup 
                                    key={this.state.popupKey}
                                    className={classes.Popup}
                                    position={this.state.popupCenter}>
                                    
                                        {popupHeader}

                                        {this.props.loadingFeatureInfo === false ?
                                            popupContent
                                            : <div></div>
                                        }

                                </Popup>

                                :null
                            }
                            
                            {this.props.aoi && this.props.aoi.geometry ? 
                                <FeatureGroup>
                                    <Pane 
                                        style={{zIndex: 550}}>

                                        <GeoJSON data={this.props.aoi.geometry}/>
                                    </Pane>
                                </FeatureGroup>
                            : null}

                            {drawFeatures}
                            {searhResult}
                            {mapLayers}
                            {queryMapLayers}
                            
                            <PrintControl ref={(ref) => { this.printControlRef = ref; }} sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hidden={true} hideControlContainer={true} />
                            <CoordinatesControl 
                                coordinates="decimal"
                                position="bottomleft"
                            />                            
                        </Map>
                    </ReactResizeDetector>

        return(
            <Aux>
                <Panel 
                    show={this.props.activeDataButton !== null ? true : false}
                    drawForm={this.props.drawing ? true : false}
                    fullWidthForm={this.props.panelFullWidth ? true : false}
                    fullHeightForm={this.props.panelFullHeight ? true : false}
                    onClose={() => this.props.onClosePanel(null)}
                >
                    {panelContent}
                </Panel>

                <div className={fullScreenPopupClasses.join(' ')}>
                    <div className={classes.Curtain}></div>
                    <div className={classes.PopupFullScreen}>
                        {popupHeader}
                        {this.props.loadingFeatureInfo === false ?
                            popupContent
                            : <div></div>
                        }
                    </div>
                </div>

                

                {this.props.swipe 
                    ? <Swipe 
                        bounds={this.props.mapBounds}
                        basemapUrl={this.props.basemapLayers[this.props.selectedBasemap].url}
                        leftImage={this.props.mapLayers[this.props.leftSwipemapLayerIndex]}
                        rightImage={this.props.mapLayers[this.props.rightSwipemapLayerIndex]}
                    />
                    
                    : map
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        phpUrl: state.system.phpUrl,
        activeDataButton: state.content.activeDataButton,
        drawing: state.content.drawing,
        mapLayerCoords: state.content.mapLayerCoords,
        aoi: state.content.aoi,
        mapLayers: state.layerItems.mapLayers,
        mapBounds: state.layerItems.mapBounds,
        searchResultItem: state.layerItems.searchResultItem,
        panelFullWidth: state.content.panelFullWidth,
        panelFullHeight: state.content.panelFullHeight,
        basemapLayers: state.content.basemapLayers,
        selectedBasemap: state.content.selectedBasemap,
        featureInfoLayers: state.content.featureInfoLayers,
        showPopup: state.content.showPopup,
        loadingFeatureInfo: state.content.loadingFeatureInfo,
        currentFeatureInfoLayer: state.content.currentFeatureInfoLayer,
        queryMapLayers: state.layerItems.queryMapLayers,
        mapKey: state.layerItems.mapKey,
        swipe: state.content.swipe,
        syncMapBounds: state.content.syncMapBounds,
        leftSwipemapLayerIndex: state.content.leftSwipemapLayerIndex,
        rightSwipemapLayerIndex: state.content.rightSwipemapLayerIndex,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onClosePanel: (index) => dispatch(actions.clickDataButton(index)),
        onAddDrawPoint: (latlng) => dispatch(actions.addDrawPoint(latlng)),
        onRemoveDrawLastPoint: () => dispatch(actions.removeDrawLastPoint()),
        onGetMapLayers: () => dispatch(actions.getMapLayers()),
        onAddLayerLoading: (mapLayerIndex, collection) => dispatch(actions.addLayerLoading(mapLayerIndex, collection)),
        onRemoveLayerLoading: (mapLayerIndex, collection) => dispatch(actions.removeLayerLoading(mapLayerIndex, collection)),
        onGetFeatureInfo: (url, layers) => dispatch(actions.getFeatureInfo(url, layers)),
        onClosePopup: () => dispatch(actions.closePopup()),
        onChangeFeaturePanel: (action) => dispatch(actions.changeFeaturePanel(action)),
        onUpdateMapKey: () => dispatch(actions.updateMapKey()),
        onUpdateMapBounds: (mapBounds) => dispatch(actions.updateMapBounds(mapBounds)),
        onProxy: () => dispatch(actions.proxy()),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);